@import url("https://fonts.googleapis.com/css2?family=Inter&display=swap");

@font-face {
  font-family: "typold-extended-regular";
  src: url(../assets/fonts/Typold-Extended-Regular.otf);
}

@font-face {
  font-family: "typold-extended-medium";
  src: url(../assets/fonts/Typold-Extended-Medium.otf);
}

@font-face {
  font-family: "typold-extended-bold";
  src: url(../assets/fonts/Typold-Extended-Bold.ttf);
}

@font-face {
  font-family: "typold-bold";
  src: url(../assets/fonts/Typold-Bold.ttf);
}

@font-face {
  font-family: "typold-condensed-medium";
  src: url(../assets/fonts/Typold-Condensed-Medium.ttf);
}

@font-face {
  font-family: "barlow-semi-condensed";
  src: url(../assets/fonts/BarlowSemiCondensed-Regular.ttf);
}

@font-face {
  font-family: "SF Pro Display Regular";
  src: url(../assets/fonts/SFProDisplay-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "SF Pro Display Medium";
  src: url(../assets/fonts/SFProDisplay-Medium.ttf) format("truetype");
}

@font-face {
  font-family: "SF Pro Display SemiBold";
  src: url(../assets/fonts/SFProDisplay-Semibold.ttf) format("truetype");
}


/* FONT */
//Family
$font-family: "Inter";

//Size
$fontXXL: 30px;
$fontXL: 24px;
$fontL: 16px;
$fontM: 14px;
$fontS: 12px;
$fontXS: 10px;

//Thickness
$font-bolder: 600;
$font-bold: 500;
$font-normal: 400;

//Line height
$ln-height-XXL: 38px;
$ln-height-L: 24px;
$ln-height-M: 20px;
$ln-height-S: 18px;
$ln-height-XS: 16px;

/* BOX SHADOW */
$bx-shadow-v1: 0px 1px 2px rgba(16, 16, 16, 0.05);

/* COLOR */
//black
$blackV1: #101010;
$blackV2: #191919;

//yellow
$yellowV1: #c1af82;

//grey
$greyV1: #676767;
$greyV2: #9e9e9e;
$greyV3: #d9d9d9;
$greyV4: #ececec;

//green
$greenV1: #494949;
$greenV2: #219653;

//red
$redV1: #af0000;

/* Margin */
//Bottom
$mb-M: 8px;

/* Modal Z Index */
$zIndex: 99999;

/* EXTENDS */

//Text
@mixin text-30($color: #101010) {
  font-weight: 500;
  font-size: 30px;
  line-height: 38px;
  color: $color;
}
@mixin text-24($color: #101010) {
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
  color: $color;
}
@mixin text-18($color: #101010) {
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  color: $color;
}
@mixin text-16($color: #101010) {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: $color;
}
@mixin text-14($color: #101010) {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: $color;
}

@mixin text-12($color: #101010) {
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: $color;
}

@mixin text-10($color: #101010) {
  font-weight: 400;
  font-size: 10px;
  line-height: 16px;
  color: $color;
}

//Icons
@mixin svg-v1 {
  path {
    fill: none;
  }
}

@mixin svg-m {
  width: 24px;
  height: 24px;
}

@mixin svg-xs {
  width: 24px;
  height: 24px;
}

@mixin svg-12 {
  width: 12px;
  height: 12px;
}

//Flexex
@mixin flex-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@mixin flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

@mixin flex-col-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@mixin flex-col-end {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
}

//Box
@mixin box-v1 {
  background: #101010;
  box-shadow: 0px 1px 2px rgba(16, 16, 16, 0.05);
  border-radius: 6px;
}

@mixin box-v2 {
  border: 0.5px solid #ececec;
  box-shadow: 0px 1px 3px rgba(16, 16, 16, 0.1), 0px 1px 2px rgba(16, 16, 16, 0.06);
  border-radius: 6px;
}

@mixin btn-black {
  border: none;
  background: $blackV1;
  padding: 16px;
  box-shadow: $bx-shadow-v1;
  border-radius: 6px;
  cursor: pointer;
  span {
    color: #fff;
    font-weight: $font-bold;
    font-size: $fontL;
    line-height: $ln-height-M;
  }
  &.disabled {
    background: $greyV3;
    cursor: default;
  }
  &:active {
    background: #fff;
    border: 1px solid $greyV1;
    color: $blackV1;
  }
}

@mixin btn-white {
  border: none;
  background: #fff;
  padding: 10px 16px;
  box-shadow: $bx-shadow-v1;
  border-radius: 6px;
  cursor: pointer;
  border: 1px solid #ECECEC;
  span {
    color: $blackV1;
    font-weight: $font-bold;
    font-size: $fontL;
    line-height: $ln-height-M;
  }
  &.disabled {
    background: $greyV3;
    cursor: default;
  }
  &:active {
    background: $blackV1;
    color: white;
  }
}

@mixin btn-border {
  border: 1px solid #d9d9d9;
  box-shadow: 0px 1px 2px rgba(16, 16, 16, 0.05);
  span {
    font-size: 14px;
  }
  svg {
    width: 14px;
    height: 14px;
  }
}