#kredit-konsumen-wrapper{
  width: 100%;
  height: 100vh;
  overflow-y: auto;
  display: flex;
  #confirmation-modal-v1{
    &.success-send-message-ctbc{
  
    }
  }
  @import "./KKComponents/index.scss";
}