.wrapper {
  span {
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #101010;
    margin-right: 16px;
  }
  .label {
    color: #9e9e9e;
    margin-right: 0;
  }
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #101010;
  .required {
    color: #af0000;
  }
}
