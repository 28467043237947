#kmod-data-kantor {
  padding: 0 40px;
  .info-container {
    margin-bottom: 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .kmod-data-kantor-table {
    background: #141414;
    border-radius: 32px;
    padding: 32px;
    background: #141414;
    border: 1px solid #1a1a1a;
    border-radius: 16px;
  }
}
