.title {
  display: flex;
  margin-bottom: 16px;
}

.durasi-grey {
  color: #d9d9d9;
}

.select-wrapper,
.input-wrapper {
  margin-bottom: 40px;
}

.align-center{
  align-items: center;
}


.required {
  color: #af0000;
}

.shadow {
  color: #d9d9d9;
}

.komisi-wrapper{
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 40px;

}

.select-wrapper {
  position: relative;
  display: flex;
  align-items: center;
  margin-right: 24px;
  margin-bottom: 0;

  span {
    color: #101010;
    font-size: 36px;
    font-family: Inter;
    line-height: 44px;
    letter-spacing: -0.72px;
  }

  svg {
    margin-left: 8px;
    width: 32px;
    height: 32px;
  }

  .commision-types {
    position: absolute;
    left: 0;
    top: 50px;

    display: flex;
    flex-direction: column;
    border: 1px solid #d9d9d9;
    border-radius: 6px;
    background: #ffffff;
    span {
      padding: 10px 16px;
      cursor: pointer;

      &:first-child {
        border-bottom: 1px solid #d9d9d9;
      }
    }
  }
}
