#konfirmasi-ganti-lead-status {
  .modal-content {
    background: white;
    padding: 16px 24px 32px;
    width: 488px;
    border-radius: 6px;
    .close-icon-wrapper {
      display: flex;
      justify-content: end;
      margin-bottom: 32px;
      svg {
        width: 16px;
        height: 16px;
        cursor: pointer;
      }
    }

    .title {
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      color: #101010;
      margin-bottom: 24px;
    }

    .info {
      font-weight: 400;
      font-size: 12px;
      line-height: 18px;
      color: #676767;
      margin-bottom: 32px;
      span {
        color: #101010;
      }
    }

    .button-wrapper {
      display: flex;
      align-items: center;
      padding-top: 16px;
      border-top: 1px solid #ececec;
      justify-content: space-between;
      .ya {
        cursor: pointer;
        background: #ffffff;
        border: 1px solid #d9d9d9;
        box-shadow: 0px 1px 2px rgba(16, 16, 16, 0.05);
        border-radius: 6px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 10px 16px;
        span {
          font-weight: 500;
          font-size: 14px;
          line-height: 20px;
          color: #101010;
        }
      }
      .batal {
        cursor: pointer;
        background: #101010;
        box-shadow: 0px 1px 2px rgba(16, 16, 16, 0.05);
        border-radius: 6px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 10px 16px;
        span {
          font-weight: 500;
          font-size: 14px;
          line-height: 20px;
          color: #ffffff;
        }
      }
    }
  }
}
