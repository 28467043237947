.dropdown-wrapper {
  padding-bottom: 8px;
  border-bottom: 1px solid #9e9e9e;
  position: relative;

  &.disabled {
    background: #f5f5f5;
    .selected-value {
      background: #f5f5f5;
    }
  }
  .selected-value {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    input {
      background: #fff;
      border: none;
      width: 100%;
      padding: 8px 0;

      font-style: normal;
      font-weight: 300;
      font-size: 36px;
      line-height: 44px;
      color: #101010;

      &::placeholder {
        font-style: normal;
        font-weight: 300;
        font-size: 36px;
        line-height: 44px;
        color: #d9d9d9;
      }
      &:focus {
        outline: none;
      }

      &:disabled {
        background: #f5f5f5;
      }
    }

    svg {
      cursor: pointer;
      width: 32px;
      height: 32px;
    }
  }
  .dropdown {
    position: absolute;
    left: 0;
    top: 86px;
    width: 100%;
    background: #ffffff;
    display: flex;
    flex-direction: column;
    border-bottom: 2px dashed #101010;
    max-height: 300px;
    overflow: auto;

    button {
      background: #f5f5f5;
      cursor: pointer;
      border: none;
      border: 1px solid #ececec;
      border-radius: 8px;
      margin-bottom: 8px;
      padding: 12px 24px;
      text-align: left;
      span {
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        color: #101010;
      }

      &:hover {
        background: #ececec;
      }
    }
  }
}

.pb-0{
  padding-bottom: 0;
}

.ml-50{
  margin-left: 50px;
}
.mr-50{
  margin-right: 50px;
}