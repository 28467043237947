.lt-header-container {
  padding: 32px;
  border-bottom: 0.5px solid #ececec;
  box-shadow: 0px 4px 3px rgba(16, 16, 16, 0.1), 0px 1px 2px rgba(16, 16, 16, 0.06);

  //TOP Section
  .lt-ts-wrapper {
    .lt-ts-header {
      @include text-30;
    }
    .top-buttons-wrapper {
      .filter-btn {
        padding: 10px;
        border: 1px solid #d9d9d9;
        box-shadow: 0px 1px 2px rgba(16, 16, 16, 0.05);
        span {
          font-size: 14px;
        }
        svg {
          @include svg-v1;
          @include svg-xs;
          width: 14px;
          height: 14px;
        }
      }
      .tambah-transaksi-btn {
        padding: 10px 16px;
        span {
          @include text-14($color: #fff);
        }
      }
      .c-divider {
        border: 1px solid #d9d9d9;
        height: 40px;
        width: 0.5px;
      }
      .left-up-arrow {
        border: none;
        padding: 0;
        margin: 0;
        svg {
          @include svg-v1;
          @include svg-m;
        }
      }
    }
  }
  .lt-welcome-text {
    @include text-16($color: #676767);
  }

  //BUTTON Sections
  .lt-btm-section {
    display: flex;
    justify-content: space-between;
    align-items: end;
    .card-wrapper {
      display: flex;
      width: 100%;
    }

    .lt-date-wrapper {
      @include flex-col-end;
      justify-content: space-between;
      position: relative;
      .lt-datepicker {
        padding-bottom: 16px;

        .react-datepicker {
          position: absolute;
          right: 0;
          top: 100px;
          width: auto;
          z-index: 20;
        }
        .calendar-btn {
          width: auto;
          border: 1px solid #d9d9d9;
          box-shadow: 0px 1px 2px rgba(16, 16, 16, 0.05);
          span {
            font-size: 14px;
          }
          svg {
            @include svg-v1;
            @include svg-xs;
            width: 14px;
            height: 14px;
          }
        }
      }
      .periode {
        @include text-14($color: #676767);
        font-weight: 500;
        padding-bottom: 16px;
        border-bottom: 1px solid #ececec;
        margin-bottom: 16px;
        span {
          font-weight: 700;
          color: $blackV1;
          font-size: 16px;
        }
      }

      .lth-filter-header {
        display: flex;
        align-items: center;
        box-shadow: 0px 1px 2px 0px rgba(16, 16, 16, 0.05);
        border: 1px solid #d9d9d9;
        border-radius: 6px;
        button {
          padding: 10px 16px;
          background: #ffffff;
          cursor: pointer;
          span {
            color: #101010;
            font-size: 14px;
            font-weight: 500;
            line-height: 20px;
          }
          &:first-child {
            border-radius: 6px 0px 0px 6px;
          }
          &:nth-child(2) {
            border-left: 1px solid #d9d9d9;
            border-right: 1px solid #d9d9d9;
          }
          &:last-child {
            border-radius: 0px 6px 6px 0px;
          }

          &.active {
            background: #101010;
            border: 1px solid #101010;
            span {
              color: #ffffff;
            }
          }
        }
      }
    }
  }
}

