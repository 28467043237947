.c-textarea-v1 {
  .label {
    font-weight: $font-bold;
    font-size: $fontXS;
    line-height: $ln-height-S;
    margin-bottom: 8px;
  }

  .textarea-wrapper {
    padding: 10px 16px;
    background: #fff;
    border-radius: 6px;
    box-shadow: $bx-shadow-v1;
    border: 1px solid #d9d9d9;

    textarea {
      width: 100%;
      cursor: pointer;
      color: $blackV1;
      font-size: $fontM;
      font-weight: $font-normal;
      font-family: $font-family;
      height: 121px;
      resize: none; 
      &::placeholder {
        color: $greyV2;
        font-size: $fontM;
        font-weight: $font-normal;
        font-family: $font-family;
      }
    }
    &.disabled {
      background: $greyV4;
      textarea {
        background: $greyV4;
      }
    }
  }

  .print-info {
    display: flex;
    align-items: center;
    margin-top: 8px;
    .info-icon {
      width: 9px;
      height: 9px;
      object-fit: contain;
      margin-right: 6px;
    }
    span {
      color: $greyV1;
      font-weight: $font-normal;
      font-size: $fontXS;
      line-height: $ln-height-XS;
    }
  }
}
