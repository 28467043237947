.title-and-number {
  display: flex;
  align-items: center;
  margin-bottom: 19px;
  .number {
    background: #101010;
    padding: 0 8px;
    border-radius: 4px;
    color: #ffffff;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    margin-right: 19px;

    &.none {
      background: #ffffff;
      color: #101010;
    }
  }
  .text {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #101010;
  }
}
