.dashboard-leads-table {
  width: calc(100vw - 80px);
  overflow-x: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    display: none;
  }

  .table {
    padding: 16px 32px;
    background: #ffffff;
    border: 0.5px solid #ececec;
    box-shadow: 0px 1px 3px rgba(16, 16, 16, 0.1), 0px 1px 2px rgba(16, 16, 16, 0.06);
    border-radius: 6px;
    margin: 0 32px;
    width: 2069px;
    min-height: 1283px;

    .row {
      display: flex;
      border-bottom: 1px solid #ececec;
      .cell {
        margin-right: 80px;
        span {
          display: block;
          white-space: nowrap;
        }
      }
      .tanggal {
        width: 143px;
      }
      .nama {
        width: 196px;
      }
      .phone {
        width: 126.5px;
      }
      .email {
        width: 191px;
      }
      .address {
        width: 422px;
        span {
          white-space: unset;
        }
      }
      .status {
        width: 83.5px;
      }
      .minat {
        width: 123.5px;
      }
      .status-leads {
        width: 139.5px;
      }
      .pic {
        width: 179px;
        margin-right: 0;
      }
    }

    .header {
      .cell {
        padding-top: 16px;
        padding-bottom: 8px;
        span {
          font-weight: 400;
          font-size: 12px;
          line-height: 18px;
          color: #9e9e9e;
          white-space: nowrap;
        }
      }
    }

    .body {
      height: 120px;
      .cell {
        display: flex;
        align-items: center;
      }
      .status {
        span {
          background: #fff4cc;
          border: 1px solid #e9b500;
          border-radius: 4px;
          padding: 2px 16px;
          font-weight: 500;
          font-size: 12px;
        }
      }
      .minat {
        span {
          background: #ececec;
          border-radius: 4px;
          padding: 2px 16px;
          font-weight: 500;
          font-size: 12px;
        }
      }
      .status-leads {
        .dropdown-wrapper {
          border: 1px solid #d9d9d9;
          box-shadow: inset 0px 1px 2px rgba(16, 16, 16, 0.05);
          border-radius: 6px;
          button {
            cursor: pointer;
            border-bottom: 1px solid #d9d9d9;

            display: flex;
            align-items: center;
            width: 159px;
            height: 40px;
            box-sizing: border-box;
            padding: 10px 16px;

            .left-icon {
              margin-right: 8px;
            }
            span {
              margin-right: 32px;
            }
            svg {
              width: 16px;
              height: 16px;
            }
          }
        }
      }
      .pic {
        button {
          border: 1px solid #d9d9d9;
          box-shadow: inset 0px 1px 2px rgba(16, 16, 16, 0.05);
          border-radius: 6px;
          padding: 10px 16px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 190px;
          height: 40px;
          box-sizing: border-box;
          cursor: pointer;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          span {
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            color: #101010;
            margin-right: 18px;
          }
        }
      }
    }
  }
}
