.wrapper {
  width: 100%;
  height: 100%;
  background: #fff;
  z-index: 100001;
}

.header {
  display: flex;
  padding: 32px;
  border-bottom: 0.5px solid #ececec;
  background: #fff;
  box-shadow:
    0px 1px 2px 0px rgba(16, 16, 16, 0.06),
    0px 4px 3px 0px rgba(16, 16, 16, 0.1);
  margin-bottom: 157px;
}

.header-main {
  font-size: 30px;
  font-weight: 500;
  margin-bottom: 8px;
}

.header-sub {
  color: #676767;
  font-size: 16px;
  font-weight: 400;
}

.body {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fcfcfd;
  margin-bottom: 157px;
}

.unselect-file {
  margin-bottom: 24px;
  display: flex;
  align-items: center;

  span {
    color: #676767;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    margin: 0 16px;
  }
}

.selected-file {
  margin-bottom: 24px;
  width: 100%;
  max-width: 500px;
}

.file {
  display: flex;
  padding: 10px 16px;
  align-items: center;
  justify-content: space-between;
  border-radius: 6px;
  border: 1px solid #d9d9d9;
  background: #fff;
  box-shadow: 0px 1px 2px 0px rgba(16, 16, 16, 0.05) inset;
  margin-bottom: 4px;
  width: 100%;
}

.file-name {
  display: flex;
  align-items: center;
  span {
    font-size: 14px;
  }
  svg {
    margin-right: 4px;
    cursor: pointer;
  }
}

.selected-file-info {
  margin-left: 16px;
  display: flex;
  align-items: center;
  span {
    color: #676767;
    font-size: 12px;
  }
  svg {
    margin-right: 4px;
  }
}

.body-header {
  font-size: 24px;
  font-weight: 600;
  text-align: center;
  margin-bottom: 8px;
}

.body-sub {
  color: #676767;
  font-size: 12px;
  margin-bottom: 32px;
}

.select-file-wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 24px;
}

.or {
  color: #676767;
  font-size: 12px;
  font-weight: 400;
  margin-right: 16px;
}

.btn-select-file {
  display: flex;
  padding: 10px 16px;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  border: 1px solid #d9d9d9;
  background: #fff;
  box-shadow: 0px 1px 2px 0px rgba(16, 16, 16, 0.05);
  font-size: 14px;
  font-weight: 500;
}

.info {
  display: flex;
  padding: 8px;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  background: #ececec;
  font-size: 12px;
}

.footer {
  display: flex;
  padding: 32px;
  border-top: 0.5px solid #ececec;
  background: #fff;
  box-shadow:
    0px -1px 2px 0px rgba(16, 16, 16, 0.06),
    0px -1px 3px 0px rgba(16, 16, 16, 0.1);
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100vw;
}

.back-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  cursor: pointer;
  padding: 10px 16px;
  border-radius: 6px;
  border: 1px solid #d9d9d9;
  background: #fff;
  box-shadow: 0px 1px 2px 0px rgba(16, 16, 16, 0.05);
  font-size: 14px;
  font-weight: 500;
  svg {
    margin-right: 8px;
  }
}
