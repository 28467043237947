@import "~styles/mixins.scss";

.wrapper {
  @include font-family;

  height: calc(100vh - 165px);
  display: flex;
  justify-content: center;
  align-items: center;

  .box {
    width: 663px;
  }
}

.gold {
  color: #c29d3e;
}

.bold {
  color: #101010;
  font-size: 600;
}

.prefix {
  color: #9e9e9e;
}

.mb-8 {
  margin-bottom: 8px;
}

.mb-16 {
  margin-bottom: 16px;
}

.mb-40 {
  margin-bottom: 40px;
}
