.rekap-data-chart {
  .calendar-wrapper {
    display: inline-block;
    margin-bottom: 24px;
  }
  .chart-container {
    padding: 32px;
    border: 1px solid #1a1a1a;
    border-radius: 16px;
    background: #141414;
    .title {
      font-family: "SF Pro Display";
      font-style: normal;
      font-weight: 600;
      font-size: 17px;
      line-height: 22px;
      color: #a7a7a7;
      margin-bottom: 24px;
      margin-left: 16px;
    }
    .total {
      margin-left: 16px;
      font-family: "SF Pro Display";
      font-style: normal;
      font-weight: 600;
      font-size: 17px;
      line-height: 22px;
      color: #a7a7a7;
      margin-bottom: 24px;
    }
  }
}

.kmod-chart-loader-wrapper {
  padding: 0 40px;
  .inner {
    padding: 32px;
    border: 1px solid #1a1a1a;
    border-radius: 16px;
    background: #141414;
    height: 466px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
