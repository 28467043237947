.unselected {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 48px;

  .logo {
    width: 48px;
    height: 48px;
    margin-bottom: 24px;
  }

  .header {
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 8px;
  }

  .sub-header {
    color: #676767;
    font-size: 12px;
    font-weight: 400;
    margin-bottom: 32px;
  }

  .flex-box {
    display: flex;
    align-items: center;
  }

  .or {
    color: #676767;
    font-size: 12px;
    font-weight: 400;
    margin-right: 16px;
  }

  .btn {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 16px;

    font-family: "Inter";
    font-size: 14px;
    font-weight: 500;
    border-radius: 6px;
    border: 1px solid #d9d9d9;
    background: #fff;
    box-shadow: 0px 1px 2px 0px rgba(16, 16, 16, 0.05);
  }
}

.selected {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 48px;

  .header {
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 8px;
    text-align: center;
  }

  .sub-header {
    color: #676767;
    font-size: 12px;
    font-weight: 400;
    margin-bottom: 48px;
    text-align: center;
  }

  .video-wrapper {
    position: relative;

    .close-icon {
      position: absolute;
      right: -12.907px;
      top: -12.907px;
      width: 25px;
      height: 25px;
      cursor: pointer;
    }

    video {
      width: 500px;
      height: 281px;
      border-radius: 9.68px;
    }
  }

  .video-name {
    font-family: "Inter";
    font-size: 14px;
    font-weight: 400;
    margin-top: 16px;
  }
}
