#modal-add-agent {
  .modal-content {
    width: 100%;
    max-width: 807px;
    background: #fff;
    padding: 24px;
    border: 0.5px solid #ececec;
    box-shadow: 0px 1px 3px rgba(16, 16, 16, 0.1), 0px 1px 2px rgba(16, 16, 16, 0.06);
    border-radius: 6px;
    display: flex;
    z-index: 42;

    .search-section,
    .list-section {
      width: 100%;
    }

    .search-section {
      border-right: 1px solid #ececec;
      padding-right: 32px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      .search-header {
        color: $blackV1;
        font-weight: $font-bolder;
        font-size: $fontXL;
        margin-bottom: 24px;
      }
      .search-agent {
        margin-bottom: 8px;
      }
      .search-example {
        margin-bottom: 24px;
        color: $greyV1;
        font-size: $fontS;
        line-height: $ln-height-S;
      }

      .divider {
        border: 1px solid $greyV4;
        margin-bottom: 24px;
      }
      .search-info {
        font-size: $fontS;
        line-height: $ln-height-S;
        margin-bottom: 8px;
        color: $greyV1;
      }
      .search-info-large {
        font-size: $fontM;
        line-height: $ln-height-M;
        color: $greyV2;
        font-weight: $font-bolder;
      }
      .selected-agent {
        color: $blackV1;
      }
    }

    .list-section {
      padding-left: 24px;
      .close-btn-wrapper {
        display: flex;
        justify-content: flex-end;
        margin-bottom: 8px;
        svg {
          width: 16px;
          height: 16px;
          cursor: pointer;
        }
      }
      .list-header {
        font-size: $fontS;
        line-height: $ln-height-S;
        color: $greyV1;
        margin-bottom: 32px;
      }
      .list-content {
        overflow-y: auto;
        height: 356px;
        margin-bottom: 32px;

        .agent-name {
          width: 100%;
          display: flex;
          justify-content: flex-start;
          padding: 16px;
          font-size: $fontM;
          line-height: $ln-height-M;
          color: $blackV1;
          border-bottom: 1px solid $greyV4;
          cursor: pointer;
        }
        .selected {
          background: $greyV4;
          border: none;
        }
      }
      .choose-agent-btn {
        width: 100%;
      }
    }
  }
}
