.dashboard-leads-footer {
  display: flex;
  justify-content: end;
  padding: 44px 32px;

  border-top: 0.5px solid #ececec;
  box-shadow: 0px -1px 3px rgba(16, 16, 16, 0.1), 0px -1px 2px rgba(16, 16, 16, 0.06);
  background: #ffffff;

  .pagination-wrapper {
    display: flex;
    align-items: center;
    button {
      display: flex;
      align-items: center;
      justify-content: center;

      background: #ffffff;
      border: 1px solid #d9d9d9;
      box-shadow: 0px 1px 2px rgba(16, 16, 16, 0.05);
      border-radius: 6px;
      padding: 10px 16px;
      cursor: pointer;

      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      color: #9e9e9e;

      .prev-icon {
        margin-right: 8px;
        width: 14px;
        height: 14px;
      }
      .next-icon {
        margin-left: 8px;
        width: 14px;
        height: 14px;
      }
    }
    p {
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      color: #9e9e9e;
      margin: 0 32px;
      span {
        color: #101010;
      }
    }
  }
}
