.properti-detail-image-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: #101010;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 40;
  .properti-modal-body {
    width: 100vw;
    height: 100vh;
    background: #101010;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
  }
  .image-modal-close {
    position: absolute;
    top: 60px;
    left: 54px;
    cursor: pointer;
    display: flex;
    align-items: center;
    .img-close {
      width: 24px;
      height: 24px;
      margin-right: 16px;
    }
    span {
      font-family: "Inter";
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      color: #ffffff;
    }
  }
  .image-modal-page {
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #ffffff;
    margin-bottom: 48px;
  }
  .image-modal-content {
    display: flex;
    align-items: center;
    padding: 0 48px;
    width: 100%;
    justify-content: space-between;
    .prev-arrow {
      width: 32px;
      height: 32px;
      object-fit: contain;
      cursor: pointer;
    }
    .next-arrow {
      width: 32px;
      height: 32px;
      object-fit: contain;
      cursor: pointer;
    }
    .image-wrapper {
      width: 976.75px;
      height: 651.17px;
      object-fit: contain;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }
}
