.uc-input-option-v1 {
  .label,
  .addon-label {
    font-weight: $font-bold;
    font-size: $fontXS;
    line-height: $ln-height-S;
    margin-bottom: 8px;
    display: inline-block;
  }
  .addon-label{
    margin-left: 8px;
    .mandatory-label{
      color: $redV1;
    }
  }
  .input-w-suffix{
    box-shadow: $bx-shadow-v1;
    border-radius: 6px;
    border: 1px solid #d9d9d9;
    display: flex;
    .suffix-wrapper{
      padding: 10px 16px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: $greyV4;
      width: 52px;
    }
    .textinput-wrapper {
      width: 100%;
      padding: 10px 16px;
      background: #fff;
      border-radius: 6px;
      
      input {
        width: 100%;
        height: 20px;
        cursor: pointer;
        color: $blackV1;
        font-size: $fontM;
        font-weight: $font-normal;
        font-family: $font-family;
  
        &::placeholder {
          color: $greyV2;
          font-size: $fontM;
          font-weight: $font-normal;
          font-family: $font-family;
        }
      }
      &.disabled {
        background: $greyV4;
        input {
          background: $greyV4;
        }
      }
    }
  }
}
