.wrapper {
  background: #ffffff;
  border-top: 0.5px solid #ececec;
  box-shadow: 0px -1px 3px rgba(16, 16, 16, 0.1), 0px -1px 2px rgba(16, 16, 16, 0.06);
  display: flex;
  justify-content: flex-end;
  padding: 24px;
  .box{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .back-page-btn{
      margin: 0;
    }
  }
  .pagination {
    display: flex;
    align-items: center;
    .btn {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 10px 16px;
      align-items: center;
      background: #ffffff;
      border: 1px solid #101010;
      box-shadow: 0px 1px 2px rgba(16, 16, 16, 0.05);
      border-radius: 6px;
      svg {
        width: 14px;
        height: 14px;
      }
      span {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: #101010;
      }
      &:active {
        transform: scale(0.9);
        transition: transform 0.1s ease-in-out;
      }
      &:disabled {
      border: 1px solid #d9d9d9;
        transform: unset;
        span {
          color: #9e9e9e;
        }
        svg {
          filter: blur(100%);
        }
      }
      &.sebelumnya {
        svg {
          margin-right: 8px;
        }
      }
      &.selanjutnya {
        svg {
          margin-left: 8px;
        }
      }
    }

    .info {
      margin: 0 32px;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      color: #676767;
      span {
        color: #101010;
      }
    }
  }
}
