.wrapper {
  width: calc(100vw - 193px);
  background: #ffffff;
  height: 100vh;
  margin-left: 193px;

  .content {
    width: 100%;
    height: 100%;
    overflow-y: auto;
    padding: 48px;

    .text-header {
      font-weight: 600;
      font-size: 24px;
      line-height: 32px;
      color: #797979;
      margin-top: 8px;
      margin-bottom: 64px;
    }

    .title {
      font-weight: 500;
      font-size: 30px;
      line-height: 38px;
      color: #101010;
      margin-bottom: 56px;
      span {
        color: #b5974d;
      }
    }

    .search-container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-bottom: 24px;
      border-bottom: 1px solid #ececec;
      .total {
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        color: #101010;
        span {
          color: #b5974d;
        }
      }
    }
  }
}
