@import "~styles/mixins.scss";

.wrapper {
  @include font-family;

  height: calc(100vh - 165px);
  display: flex;
  justify-content: center;
  align-items: center;

  .box {
    width: 663px;
    .title-and-number {
      display: flex;
      align-items: center;
      margin-bottom: 19px;
      .number {
        background: #101010;
        padding: 0 8px;
        border-radius: 4px;
        color: #ffffff;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        margin-right: 19px;

        &.none {
          background: #ffffff;
          color: #101010;
        }
      }
      .text {
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: #101010;
      }
    }
    .question {
      font-style: normal;
      font-weight: 400;
      font-size: 24px;
      line-height: 32px;
      color: #101010;
      margin-bottom: 16px;
    }
    .button-wrapper {
      display: flex;
      align-items: center;
      margin-bottom: 48px;
      .button {
        background: #f5f5f5;
        padding: 12px 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 24px;
        border-radius: 8px;
        cursor: pointer;
        .text {
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 18px;
          color: #101010;
        }
        .check-icon {
          width: 14px;
          height: 14px;
          margin-left: 10px;
          display: none;
        }
        &.active {
          border: 1px solid #101010;
          .check-icon {
            display: inline-block;
          }
        }
      }
    }
  }
}
