.title {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}

.telepon-debitur-info {
  align-self: center;
  margin-top: 24px;
  display: flex;
  align-items: center;
  svg {
    width: 18px !important;
    height: 18px !important;
    margin-right: 8px !important;
  }
}