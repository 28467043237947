@import "~styles/_variables.scss";

.scrolled-content {
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  overflow-x: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    display: none;
  }
  scroll-behavior: smooth;
  background: transparent;
}

.wrapper-content {
  width: 100%;
  max-width: 487px;
  background: #fff;
  padding: 16px;
  border: 0.5px solid #ececec;
  box-shadow:
    0px 1px 3px rgba(16, 16, 16, 0.1),
    0px 1px 2px rgba(16, 16, 16, 0.06);
  border-radius: 6px;
  z-index: calc($zIndex + 2);
  margin: 100px 0;
  height: fit-content;
  .wrapper-close {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 32px;
    svg {
      width: 16px;
      height: 16px;
      cursor: pointer;
    }
  }

  .text-detail-marketing {
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
    color: #101010;
    margin-bottom: 24px;
    font-family: "Inter";
  }

  .image-wrapper {
    width: 80px;
    height: 80px;
    margin-bottom: 40px;
    position: relative;
    img {
      border: 2px solid #ffffff;
      filter: drop-shadow(0px 1.17333px 2.34667px rgba(35, 35, 35, 0.06));
      border-radius: 800px;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    .btn-ubah {
      box-sizing: border-box;
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      padding: 4px 10px;
      background: #ffffff;
      border: 1px solid #ececec;
      box-shadow: 0px 1px 2px rgba(16, 16, 16, 0.06);
      border-radius: 6px;
      position: absolute;
      left: 12px;
      bottom: -4px;
      cursor: pointer;
      span {
        font-family: "Inter";
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 18px;
        color: #101010;
      }
    }
  }

  .input-wrapper {
    padding-bottom: 24px;
    .label {
      font-weight: 500;
      font-size: 12px;
      line-height: 18px;
      color: #676767;
      margin-bottom: 8px;
      font-family: "Inter";
    }

    .input,
    .input-phone {
      width: 100%;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      padding: 10px 16px;
      background: #ffffff;
      border: 1px solid #d9d9d9;
      box-shadow: inset 0px 1px 2px rgba(16, 16, 16, 0.05);
      border-radius: 6px;
      line-height: 20px;
      &:disabled, 
      &.disabled {
        background: #f5f5f5;
        border: 1px solid #d9d9d9;
      }
    }

    input,
    .prefix {
      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
    }
  }

  .btn-wrapper {
    display: flex;
    justify-content: center;
    padding-top: 16px;
    border-top: 1px solid #f5f5f5;

    .btn {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 10px 16px;
      background: #ffffff;
      border-radius: 6px;
      cursor: pointer;

      span {
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: #101010;
      }

      svg {
        width: 14px;
        height: 14px;
        margin-right: 8px;
      }

      &:active {
        transform: scale(0.9);
        transition: transform 0.1s ease-in-out;
      }
    }

    .white {
      box-sizing: border-box;
      display: flex;
      justify-content: center;
      align-items: center;
      background: #ffffff;
      border: 1px solid #d9d9d9;
      box-shadow: 0px 1px 2px rgba(16, 16, 16, 0.05);
    }
    .black {
      margin-left: 24px;
      box-sizing: border-box;
      display: flex;
      justify-content: center;
      align-items: center;
      background: #101010;
      border: 1px solid #d9d9d9;
      box-shadow: 0px 1px 2px rgba(16, 16, 16, 0.05);
      span {
        color: #ffffff;
      }
    }
  }
}

.loader-wrapper {
  position: absolute;
  z-index: 50px;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
}

.upload-new-profile-wrapper {
  margin-top: 24px;
  margin-bottom: 24px;
  .title-unggah-foto {
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    color: #676767;
    margin-bottom: 8px;
  }

  .dropzone {
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 16px;
    background: #ffffff;
    border: 1px solid #d9d9d9;
    box-shadow: 0px 1px 2px rgba(16, 16, 16, 0.05);
    border-radius: 6px;
    margin-bottom: 8px;

    svg {
      width: 14px;
      height: 14px;
      margin-right: 8px;
    }
    .text-pilih-foto {
      font-family: "Inter";
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      color: #101010;
    }
  }

  .footer-unggah-foto {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: #676767;
  }
}

.wrapper-info-profile-web {
  margin-bottom: 24px;
  .title-profile-web {
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    color: #676767;
    margin-bottom: 8px;
  }

  .desc-profile-web {
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #af0000;
    margin-bottom: 8px;

    &.green {
      color: #219653;
    }
  }

  .footer-profile-web {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: #676767;
  }
}

.wrapper-id-card-button {
  padding: 24px 0;
  margin-bottom: 24px;
  border-top: 1px solid #f5f5f5;

  .title-id-card {
    margin-bottom: 8px;
    color: #676767;
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
  }
}

.hidden-wrraper {
  // position: absolute;
  // // left: -110%;
  // z-index: 9999999;
}

.id-card-wrapper {
  position: relative;
  width: 208px;
  height: 321px;
}

.front-card {
  background: #ffffff;
  .front-card-bg {
    width: 100%;
    height: 100%;
  }

  .front-card-img {
    position: absolute;
    bottom: 0;
    right: 0;
    object-fit: contain;
    width: 70%;
  }

  .wrapper-century-logo {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    position: absolute;
    right: 10px;
    top: 24px;

    .century-logo {
      object-fit: contain;
      width: 45%;
    }
  }

  .company-name {
    position: absolute;
    top: 40px;
    right: 12px;
    font-family: "typold-extended-medium";
    font-style: normal;
    font-size: 8px;
    color: #101010;
    letter-spacing: 1px;
  }

  .name-role-reg {
    position: absolute;
    top: 105px;
    left: 11px;
  }

  .fullname {
    position: absolute;
    top: 105px;
    left: 11px;
    width: 43%;
    font-family: "typold-extended-bold";
    font-style: normal;
    font-size: 13px;
    color: #101010;
    text-transform: uppercase;
  }

  .role {
    position: absolute;
    top: 158px;
    left: 11px;
    font-family: "typold-extended-bold";
    font-style: normal;
    font-size: 7px;
    color: #101010;
    text-transform: uppercase;
  }

  .registration-number {
    position: absolute;
    top: 168px;
    left: 11px;
    font-family: "typold-extended-bold";
    font-style: normal;
    font-size: 7px;
    color: #101010;
    letter-spacing: 1px;
  }
}

.back-card {
  .back-card-bg {
    width: 100%;
    height: 100%;
  }
  .wrapper-century-logo {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 0;
    top: 30px;

    .century-logo {
      object-fit: contain;
      width: 45%;
    }
  }
  .qr-code {
    position: absolute;
    width: 83px;
    top: 64px;
    left: 62px;
    border-radius: 16px;
  }
  .company-name {
    position: absolute;
    width: 100%;
    top: 40px;
    left: 0;
    font-family: "typold-extended-medium";
    font-size: 9px;
    color: #101010;
    text-align: center;
    padding: 0 4px;
    letter-spacing: 1px;
  }

  .name-role-reg {
    position: absolute;
    top: 150px;
    width: 100%;
  }
  .fullname {
    // position: absolute;
    // top: 150px;
    // width: 100%;
    text-align: center;
    left: 0;
    font-family: "typold-extended-bold";
    font-size: 16px;
    color: #101010;
    text-transform: uppercase;
    margin-bottom: 4px;
    padding: 0 8px;
    width: 208px;
    max-height: 48px;
  }
  .role {
    // position: absolute;
    // top: 200px;
    // left: 0;
    width: 100%;
    font-family: "typold-extended-bold";
    font-size: 9px;
    color: #101010;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 3px;
    margin-bottom: 4px;
  }
  .registration-number {
    // position: absolute;
    // top: 218px;
    // left: 0;
    width: 100%;
    font-family: "typold-extended-bold";
    font-size: 6.66px;
    color: #101010;
    text-align: center;
    letter-spacing: 3px;
  }
  .phone {
    position: absolute;
    top: 226px;
    left: 0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    p {
      font-family: "typold-extended-bold";
      font-size: 11px;
      color: #101010;
      text-align: center;
      letter-spacing: 3px;
      margin-bottom: 2px;
    }
    .phone-icon {
      width: 18px;
      height: 18px;
    }
  }
  .street {
    position: absolute;
    top: 246px;
    left: 0;
    width: 100%;
    font-family: "typold-extended-bold";
    // font-size: 9px;
    font-size: 6px;
    color: #101010;
    text-align: center;
    display: flex;
    justify-content: center;
    padding: 0 8px;
    .map-icon {
      width: 16px;
      height: 16px;
      display: none;
    }
  }
  .info {
    position: absolute;
    bottom: 8px;
    left: 0;
    width: 100%;
    font-family: "typold-extended-regular";
    font-size: 6px;
    color: #101010;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 4px;
  }
}
