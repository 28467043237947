.listing-header {
  position: relative;
  padding: 32px;
  border-bottom: 0.5px solid #ECECEC;
  .title {
    font-weight: $font-bold;
    font-size: $fontXXL;
    color: $blackV1;
    line-height: $ln-height-XXL;
    margin-bottom: $mb-M;
  }
  .sub-title {
    font-weight: $font-normal;
    font-size: $fontL;
    color: $blackV1;
    line-height: $ln-height-L;
  }
  .listing-header-btn{
    position: absolute;
    right: 32px;
    top: 47px;
  }
  .selected{
    border: 1px solid $greyV3;
    background: #fff;
    span{
      color: $blackV1;
    }
  }
}
