@keyframes progress {
  10% {
    width: 10%;
  }
  25% {
    width: 25%;
  }
  75% {
    width: 75%;
  }
  80% {
    width: 80%;
  }
  95% {
    width: 95%;
  }
  100% {
    width: 100%;
  }
}
.loading-bar {
  width: 313px;
  height: 36px;
  width: 332px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  background: #ffffff;
  border: 1px solid #ececec;
  border-radius: 6px;
  .loading-text {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    z-index: 2;
    position: absolute;
    padding: 0 24px;
    span {
      left: 24px;
      color: $blackV1;
      &:last-child {
        color: $greyV1;
      }
    }
  }
  .progress-bar {
    position: absolute;
    left: 0;
    height: 100%;
    background: #c1af82;
    border-radius: 6px;
    z-index: 1;
  }
}
