.dashboard-leads-empty-data {
  width: 100%;
  height: calc(100% - 108px);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  
  .no-data-image {
    width: 289.82px;
    height: 212px;
    object-fit: contain;
    margin-bottom: 48px;
  }

  p {
    font-weight: 500;
    font-size: 24px;
    line-height: 32px;
    text-align: center;
    color: #101010;
  }
}
