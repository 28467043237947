.title-input {
  span {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
  }
  .label {
    color: #9e9e9e;
  }
  .text {
    color: #101010;
  }
  .name {
    color: #c29d3e;
  }
  .asterisk {
    color: #af0000;
  }
}