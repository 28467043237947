.lt-listing-card {
  width: 100%;
  display: flex;
  border: 1px solid #ececec;
  box-shadow: 0px 1px 2px rgba(16, 16, 16, 0.06);
  border-radius: 6px;
  cursor: pointer;
  .card-img {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 147px;
    height: 151px;
    img {
      width: 147px;
      height: 151px;
      object-fit: cover;
    }
  }

  .card-info {
    width: calc(100% - 147px);
    padding: 8px 16px;

    p {
      &:nth-child(1) {
        @include text-16($color: #9a6e00);
        margin-bottom: 16px;
        span {
          color: $blackV1;
        }
      }
      &:nth-child(2) {
        @include text-16;
        margin-bottom: 6px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      &:nth-child(3) {
        @include text-12($color: #676767);
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-bottom: 18px;
      }
    }

    .label {
      display: flex;
      align-items: center;
      white-space: nowrap;
      span {
        padding: 4px 8px;
        &:nth-child(1) {
          border-radius: 4px;
          margin-right: 8px;
          background: #d3ffe6;
          color: #006129;
          &.disewakan {
            background: #ffeeba;
            color: #624a00;
          }
        }

        &:nth-child(2) {
          border: 1px solid #ececec;
          box-shadow: 0px 1px 2px rgba(16, 16, 16, 0.06);
          border-radius: 6px;
        }
      }
    }
  }
}
