.baris-kedua {
  display: flex;
  padding: 0 40px;
  margin-bottom: 72px;
  .card-wrapper {
    width: 100%;
    &:first-child {
      margin-right: 40px;
    }

    .calendar-wrapper {
      margin-bottom: 24px;
      display: inline-block;
    }
  }
}
