@import "../../../../styles/fonts";

#kmod-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 32px 40px;
  padding-bottom: 0;
  margin-bottom: 96px;

  .title {
    p{
      &:first-child {
        font-family: "SF Pro Display";
        font-style: normal;
        font-weight: 600;
        font-size: 28px;
        line-height: 34px;
        color: #e9eef6;
        margin-bottom: 8px;
      }
      &:last-child {
        font-family: "SF Pro Display";
        font-style: normal;
        font-weight: 400;
        font-size: 17px;
        line-height: 22px;
        color: #a7a7a7;
      }
    }
  }

  .buttons {
    display: flex;
    align-items: center;
    button {
      box-sizing: border-box;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 8px 16px;
      background: #090909;
      border: 1px solid #a7a7a7;
      border-radius: 32px;
      margin-right: 35px;
      cursor: pointer;
      svg {
        width: 24px;
        height: 24px;
        margin-right: 8px;
      }

      span {
        font-family: "SF Pro Display";
        font-style: normal;
        font-weight: 600;
        font-size: 13px;
        line-height: 18px;
        color: #e9eef6;
      }
    }
    .signout {
      width: 24px;
      height: 24px;
      cursor: pointer;
    }
  }
}
