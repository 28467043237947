.title {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}

.required {
  color: #af0000;
}

.shadow {
  color: #9e9e9e;
}
