@import "../../../../../styles/fonts";

.loader-wrapper{
  width: 100%;
  height: 290px;
  padding: 24px;
  background: #141414;
  border: 1px solid #1a1a1a;
  border-radius: 16px;
  display: flex;
  justify-content: center;
  align-items: center;  
}

.price-card {
  width: 100%;
  height: 290px;
  padding: 24px;
  background: #141414;
  border: 1px solid #1a1a1a;
  border-radius: 16px;

  .price-wrapper {
    border-bottom: 1px solid #a7a7a7;
    margin-bottom: 8px;
    .title {
      font-family: "SF Pro Display";
      font-style: normal;
      font-weight: 600;
      font-size: 17px;
      line-height: 22px;
      color: #a7a7a7;
      margin-bottom: 45px;
    }
    .price {
      font-family: "SF Pro Display";
      font-style: normal;
      font-weight: 300;
      font-size: 48px;
      line-height: 60px;
      color: #fff;
      span {
        color: #a7a7a7;
      }
    }
    .total {
      font-family: "SF Pro Display";
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
      line-height: 18px;
      color: #a7a7a7;
      margin-bottom: 8px;
    }
  }

  .ut-value {
    font-family: "SF Pro Display";
    font-style: normal;
    font-weight: 400;
    font-size: 48px;
    line-height: 60px;
    color: #e9eef6;
    margin-bottom: 8px;
  }
  .ut-label {
    font-family: "SF Pro Display";
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 18px;
    color: #a7a7a7;
  }
}
