.pilih-listing-wrapper {
  .divider {
    border: 1px solid #d9d9d9;
  }

  .pl-header {
    @include flex-between;
    padding: 32px;
    border-bottom: 0.5px solid #ececec;
    box-shadow: 0px 1px 3px rgba(16, 16, 16, 0.1), 0px 1px 2px rgba(16, 16, 16, 0.06);
    .back-btn {
      @include btn-border;
      width: auto;
    }
  }

  .pl-body {
    @include flex-center;
    padding: 55px 32px;
    min-height: calc(100vh - 115px - 117px);
    .pl-body-wrapper {
      padding: 24px;
      width: 100%;
      min-height: calc(508px + 32px + 36px + 20px);
      @include box-v2;

      .pl-text {
        @include text-24;
        margin-bottom: 8px;
      }

      .agent-terpilih {
        @include flex-between;

        .agent-terpilih-card {
          display: flex;
          align-items: center;
          .agent-name {
            @include text-14($color: #676767);
            span {
              color: #101010;
            }
          }
          .atc-divider {
            border: 1px solid #d9d9d9;
            height: 26px;
            width: 0.5px;
          }
        }
      }

      .listing-card-wrapper {
        display: grid;
        grid-template-columns: repeat(3, 31%);
        grid-gap: 24px;
        justify-content: space-between;
      }
    }
  }

  .pl-footer-wrapper {
    @include flex-col-end;
    padding: 32px;
    border-top: 0.5px solid #ececec;
    box-shadow: 0px -1px 3px rgba(16, 16, 16, 0.1), 0px -1px 2px rgba(16, 16, 16, 0.06);
    background: #fff;
    .goto-wrapper {
      display: flex;
      align-items: center;
      .goto-text {
        @include text-14($color: #676767);
      }
      .goto-btn {
        width: auto;
        flex-direction: row-reverse;
        background: #ececec;
        padding: 15px;
        border: 1px solid #d9d9d9;
        border-radius: 8px;
        svg {
          margin-left: 24px;
          margin-right: 0;
          @include svg-v1;
        }
      }
    }

    .pagination-wrapper {
      display: flex;
      align-items: center;
      .prev-btn {
        @include svg-v1;
        span {
          @include text-14($color: #9e9e9e);
        }
        @include btn-border;
      }
      .pagination-text {
        @include text-14($color: #9e9e9e);
        span {
          color: $blackV1;
        }
      }
      .next-btn {
        @include svg-v1;
        flex-direction: row-reverse;
        span {
          @include text-14($color: #9e9e9e);
        }
        svg {
          margin-left: 13px;
          margin-right: 0;
          @include svg-v1;
        }
        @include btn-border;
      }
    }
  }
}
