#modal-confirmasi-transaksi {
  .modal-content {
    width: 100%;
    max-width: 500px;
    background: #fff;
    padding: 32px;
    border: 0.5px solid #ececec;
    box-shadow: 0px 1px 3px rgba(16, 16, 16, 0.1), 0px 1px 2px rgba(16, 16, 16, 0.06);
    border-radius: 6px;

    .divider{
      border: 1px solid #ECECEC;
      margin-top: 32px;
      margin-bottom: 32px;
    }

    .close-wrapper{
      display: flex;
      justify-content: flex-end;
    }

    .btn-wrapper{
      .left-btn,
      .right-btn{
        width: 100%;
        white-space: nowrap;
      }
      .left-btn{
        @include btn-white;

      }
      .right-btn{
        margin-left: 8px;
        &.disabled {
          span{
            color: $blackV1;
          }
        }
      }
    }
  }
}
