.btn {
  margin-top: 48px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 16px;
  background: #101010;
  box-shadow: 0px 1px 2px rgba(16, 16, 16, 0.05);
  border-radius: 6px;
  cursor: pointer;
  span {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #ffffff;
    margin-right: 8px;
  }

  svg {
    width: 14px;
    height: 14px;
  }

  &:hover {
    background-color: #282828;
  }

  &:active {
    transform: scale(0.9);
    transition: transform 0.1s ease-in-out;
  }
}
.outline-only {
  margin-top: 48px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 16px;
  background: #ffffff;
  border: 1px solid #101010;
  border-radius: 6px;
  cursor: pointer;
  span {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #101010;
    margin-right: 8px;
  }

  svg {
    width: 14px;
    height: 14px;
  }

  &:hover {
    background-color: #282828;
  }

  &:active {
    transform: scale(0.9);
    transition: transform 0.1s ease-in-out;
  }
}
