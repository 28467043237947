.range-datepicker-input {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 16px;
  background: #141414;
  border-radius: 32px;
  white-space: nowrap;
  min-width: 282px;
  position: relative;
  .calendar-icon {
    width: 24px;
    height: 24px;
    margin-right: 8px;
  }
  .arrow-down {
    width: 16px;
    height: 16px;
    margin-left: 32px;
  }

  .react-datepicker__input-container {
    input {
      background: #141414;
      color: #a7a7a7;
      border: none;
      width: 100%;
      font-size: 12px;
    }
  }

  .react-datepicker{
    width: 282px;
    left: -40px;
    right: 50px;
    .react-datepicker__month-container{
      width: 100%;
    }
  }
}
