@import "~styles/mixins.scss";

.wrapper {
  @include font-family;

  height: calc(100vh - 165px);
  display: flex;
  justify-content: center;
  align-items: center;

  .box {
    width: 663px;
  }
}

.golden {
  color: #c29d3e;
}

.margin-left{
  margin-left: 45px;
}
