.properti-detail-deskripsi {
  margin-bottom: 40px;
  padding-bottom: 40px;
  border-bottom: 1px solid #ececec;
  .detail-deskripsi-header {
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    color: #101010;
    margin-bottom: 24px;
  }
  .detail-deskripsi-content {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #494949;
    margin-bottom: 16px;
    white-space: pre-line;
  }
  .detail-descripsi-selanjutnya {
    display: inline-block;
    border-bottom: 1px solid #101010;
    visibility: hidden;
    cursor: pointer;
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: #101010;
  }
}
