.period-wrapper {
  padding: 0 40px;
  margin-bottom: 24px;

  display: flex;
  align-items: center;
  .calendar-wrapper{
    margin-right: 40px;
  }
}
.baris-pertama {
  display: flex;
  padding: 0 40px;
  margin-bottom: 72px;
  .card-wrapper {
    width: 100%;
    .header-wrapper {
      display: flex;
      flex-direction: column;
    }
    &:first-child {
      margin-right: 40px;
    }

    .calendar-wrapper {
      margin-bottom: 24px;
      display: inline-block;
    }
  }
}
