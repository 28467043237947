.wrapper {
  padding: 32px 0;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #f5f5f5;
}

.image {
  width: 22.87%;
  height: 254px;
  margin-right: 32px;
  border: 1px solid #ececec;
  filter: drop-shadow(0px 1px 2px rgba(16, 16, 16, 0.06));
  border-radius: 16px;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 16px;
  }
}

.content {
  width: 77.13%;
  height: 254px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .price {
    font-weight: 600;
    font-size: 30px;
    line-height: 38px;
    background: linear-gradient(90deg, #c1af82 -6.5%, #75612f 50%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    margin-bottom: 8px;
  }

  .features {
    display: flex;
    align-items: center;
    span {
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: #494949;
    }
    .feature-dots {
      width: 8px;
      height: 8px;
      margin-right: 8px;
      margin-left: 8px;
    }
  }

  .property-sizing {
    font-weight: 500;
    font-size: 24px;
    line-height: 32px;
    color: #101010;
    margin-bottom: 24px;
  }

  .sizing-list {
    display: grid;
    grid-template-columns: repeat(3, calc(100% / 3));
    grid-column-gap: 16px;
    grid-row-gap: 32px;

    p {
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #494949;
      width: 100%;
      span {
        color: #101010;
        font-weight: 700;
      }
      .sizing-dots {
        width: 8px;
        height: 8px;
        margin-right: 8px;
      }
    }
  }
}
