@import url("../../../../styles/_variables.scss");
.list-listing {
  width: 65%;
  padding: 32px 24px 68px 24px;
  box-sizing: border-box;
  .wrapper-header-list-listing {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    .button-wrapper-list-listing {
      display: flex;
      align-items: center;
      border-radius: 6px;
      border: 1px solid #d9d9d9;
      box-shadow: 0px 1px 2px 0px rgba(16, 16, 16, 0.05);
      .btn-filter-list-listing {
        display: flex;
        padding: 8px 12px;
        justify-content: center;
        align-items: center;
        box-sizing: border-box;
        background: #ffffff;
        cursor: pointer;
        white-space: nowrap;
        border-right: 1px solid #d9d9d9;
        &:first-child {
          border-radius: 6px 0px 0px 6px;
        }
        &:last-child {
          border-radius: 0px 6px 6px 0px;
          border-left: 1px solid #d9d9d9;
        }
      }

      .active {
        background: #101010;
        color: #ffffff;
      }
      .temp {
        // border-radius: 6px 0px 0px 6px;
        border-right: 1px solid #d9d9d9;
      }
    }
  }
  .list-listing-header {
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 30px;
    color: #101010;
    margin-bottom: 8px;
  }
  .list-listing-sub-header {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    color: #676767;
    margin-bottom: 56px;
  }
  .inactive-banner-box {
    align-items: center;
    border-radius: 6px;
    border: 1px solid #fec601;
    background: #fff4cc;
    box-shadow: 0px 4px 64px 0px rgba(16, 16, 16, 0.08);
    padding-top: 8px;
    padding-bottom: 8px;
    padding-right: 10px;
    padding-left: 10px;
    margin-bottom: 24px;
  }

  .inactive-banner-title {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #101010;
    line-height: 20px;
  }
  .box-banner-left {
    flex-direction: row;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .warning-icon {
    width: 20px;
    height: 20px;
    margin-right: 8px;
  }
  .list-listing-table-wrapper {
    background: #ffffff;
    border: 1px solid #ececec;
    box-shadow: 0px 4px 64px rgba(16, 16, 16, 0.08);
    border-radius: 6px;
    .search-n-fiter-wrapper {
      display: flex;
      width: 100%;
      padding: 16px 24px;

      .custom-search-box {
        display: flex;
        align-items: center;
        width: 100%;

        span {
          color: #212121;
          margin-right: 8px;
          font-family: "Inter";
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          white-space: nowrap;
        }
        input {
          color: #212121;
          margin-right: 8px;
          font-family: "Inter";
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          width: 100%;
          padding: 10px 12px;

          border-radius: 6px;
          border: 1px solid #d9d9d9;
          background: #fff;
        }
      }
      .marketing-agent-name {
        .label {
          margin: 0;
        }
      }
    }
    .table-pagination-wrapper {
      min-height: 858px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    .list-listing-table {
      .table-row {
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        padding: 24px;
        .table-data {
          display: flex;
          align-items: center;
          justify-content: flex-start;
        }
      }

      .table-header {
        padding: 8px 0 16px 0;
        .table-row {
          .table-data span {
            font-family: "Inter";
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            color: #9e9e9e;
          }
        }
      }
      .table-body {
        .table-row {
          cursor: pointer;
          border-bottom: 1px solid #ececec;
          .table-data span {
            font-family: "Inter";
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            color: #101010;
          }
          &.selected {
            background: #f6f6f6;
            .table-data span {
              font-weight: 500;
            }
          }
        }
      }
    }
    .table-pagination {
      display: flex;
      justify-content: flex-end;
      margin-top: 48px;
      margin-bottom: 24px;
      margin-right: 24px;
      .pagination-wrapper {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        .custom-btn-with-icon {
          width: auto;
        }

        .prev-btn {
          path {
            fill: none;
          }
          &.disabled {
            span {
              color: #9e9e9e;
            }
          }
          span {
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;
            color: #101010;
          }
          border: 1px solid #d9d9d9;
          box-shadow: 0px 1px 2px rgba(16, 16, 16, 0.05);
          span {
            font-size: 14px;
          }
          svg {
            width: 14px;
            height: 14px;
          }
        }
        .pagination-text {
          font-weight: 500;
          font-size: 14px;
          line-height: 20px;
          color: #9e9e9e;
          span {
            color: #101010;
          }
        }
        .next-btn {
          path {
            fill: none;
          }
          flex-direction: row-reverse;
          &.disabled {
            span {
              color: #9e9e9e;
            }
          }
          span {
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;
            color: #101010;
          }
          svg {
            margin-left: 13px;
            margin-right: 0;
            path {
              fill: none;
            }
          }
          border: 1px solid #d9d9d9;
          box-shadow: 0px 1px 2px rgba(16, 16, 16, 0.05);

          svg {
            width: 14px;
            height: 14px;
          }
        }
      }
    }
  }
}

#modal-active-nonactive {
  width: 100%;
  max-width: 300px;
  background: #fff;
  padding: 32px;
  border: 0.5px solid #ececec;
  box-shadow:
    0px 1px 3px rgba(16, 16, 16, 0.1),
    0px 1px 2px rgba(16, 16, 16, 0.06);
  border-radius: 6px;
  z-index: 100001;
  .btn-wrapper {
    display: flex;
    justify-content: flex-end;
  }

  .text {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: #676767;
    margin-bottom: 24px;
    margin-top: 24px;
  }
  .wrapper-btn {
    display: flex;
    justify-content: center;
    .custom-btn {
      span {
        font-family: "Inter";
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
      }
    }
  }
}
