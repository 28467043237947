.wrapper-search {
  display: flex;
  align-items: center;
  padding: 16px 24px;
  .search-info {
    color: #212121;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    margin-right: 8px;
    white-space: nowrap;
  }

  input {
    border: 1px solid #9e9e9e;
    border-radius: 4px;
    color: #212121;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    margin-right: 8px;
    padding: 10px 12px;
    width: 100%;
  }
}

.wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 68px;
  min-height: calc(100vh - 250px);

  .cards-wrapper {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, calc(34% - 24px));
    grid-auto-rows: 151px;
    grid-gap: 24px;

    @media (max-width: 1200px) {
      grid-template-columns: repeat(2, calc(50% - 24px));
    }
    @media (max-width: 865px) {
      grid-template-columns: 100%;
    }
  }
}

.empty-listing {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  p {
    &:nth-child(2) {
      font-weight: 500;
      font-size: 24px;
      line-height: 32px;
      text-align: center;
      color: #101010;
      margin-bottom: 16px;
    }
    &:last-child {
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      text-align: center;
      color: #676767;
    }
  }
}
