.map-field-v1 {
  .map-field-title {
    font-weight: $font-bold;
    font-size: $fontS;
    line-height: $ln-height-S;
    color: $greyV1;
    margin-bottom: 8px;
  }
  .outter-map-field{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
  .map-field-wrapper {
    display: flex;
    justify-content: space-between;
    .map-field-textarea {
      width: 100%;
      .label {
        display: none;
      }
      .textarea-wrapper textarea {
        min-height: 68px;
        height: auto;
        background: #fff;
      }
      .textarea-wrapper {
        &.disabled {
          background: #fff;
        }
      }
    }
  }
  .map-address {
    display: flex;
    width: 90%;
    margin-top: 16px;
    .map-pin {
      width: 24px;
      height: 24px;
      margin-right: 8px;
    }
    .map-pin-street {
      font-size: $fontM;
      line-height: $ln-height-M;
      color: $blackV1;
    }
  }
}
