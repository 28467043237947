.wrapper {
  height: calc(100vh - 165px);
  display: flex;
  justify-content: center;
  align-items: center;

  .box{
    width: 663px;
  }
}

.title{
  display: flex;
  align-items: center;
  margin-bottom: 40px;
}
