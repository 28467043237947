.title {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}

.error-message {
  color: #af0000;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 32px;
  margin-top: 24px;
}
