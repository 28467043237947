.table-wrapper {
  .top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 40px;
    p {
      font-family: "SF Pro Display";
      font-style: normal;
      font-weight: 600;
      font-size: 17px;
      line-height: 22px;
      color: #ffffff;
    }
    button {
      background: #090909;
      border: 1px solid #a7a7a7;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 11px 16px;
      border-radius: 32px;
      font-family: "SF Pro Display";
      font-style: normal;
      font-weight: 600;
      font-size: 13px;
      line-height: 18px;
      letter-spacing: -0.08px;
      color: #ffffff;
    }
  }
  .table-wrapper-right {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
  .export-refresh {
    margin-right: 20px;
  }
  .tabel-rdk-wrapper {
    display: flex;
    justify-content: center;
    .table-loader-wrapper {
      width: 1200px;
      height: 405px;
      background: #141414;
      border-radius: 32px;
    }
    table {
      width: 100%;
      border-radius: 6px;
      thead,
      tfoot {
        tr {
          td {
            font-family: "SF Pro Display";
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 16px;
            color: #7b7b7b;
            padding: 8px;
            text-align: center;
            background: #232323;
            .updown {
              width: 8px;
              height: 14px;
              margin-left: 8px;
            }
          }
        }
      }
      tbody {
        tr {
          td {
            font-family: "SF Pro Display";
            font-style: normal;
            font-weight: 600;
            font-size: 17px;
            line-height: 22px;
            letter-spacing: -0.43px;
            color: #ffffff;
            padding: 8px;
            text-align: center;
            border-bottom: 1px solid #232323;
            &:nth-child(1) {
              font-family: "SF Pro Display";
              font-style: normal;
              font-weight: 400;
              font-size: 13px;
              line-height: 18px;
              letter-spacing: -0.08px;
              color: #4f4f4f;
            }
            &:nth-child(2) {
              font-family: "SF Pro Display";
              font-style: normal;
              font-weight: 400;
              font-size: 13px;
              line-height: 18px;
              letter-spacing: -0.08px;
              color: #ffffff;
              &.highest {
                color: #00ff86;
              }
            }
            span {
              .highest {
                color: #00ff86;
                &td:nth-child(2) {
                  color: #00ff86;
                }
              }
              .lowest {
                color: #ff0000;
              }
            }
          }
          .highest {
            color: #00ff86;
            &td:nth-child(2) {
              color: #00ff86;
            }
          }
          .lowest {
            color: #ff0000;
          }
        }
      }
    }
  }
}
