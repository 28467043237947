#modal-pilih-listing-v2 {
  .modal-content {
    width: 100%;
    max-width: 422px;
    background: #fff;
    padding: 32px;
    border: 0.5px solid #ececec;
    box-shadow: 0px 1px 3px rgba(16, 16, 16, 0.1), 0px 1px 2px rgba(16, 16, 16, 0.06);
    border-radius: 6px;

    .divider {
      border: 1px solid #ececec;
      margin-top: 32px;
      margin-bottom: 32px;
    }

    .close-wrapper {
      display: flex;
      justify-content: flex-end;
    }

    .listing-card {
      cursor: pointer;
      padding: 16px 32px;
      border: 1px solid #d9d9d9;
      border-radius: 6px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      div {
        width: 70%;
      }
      input[type="radio"] {
        // width: 30%;
      }

      &.selected {
        background: #101010;
        border: 1px solid #c1af82;
        box-shadow: 0px 4px 64px rgba(16, 16, 16, 0.08);
        border-radius: 6px;
        p {
          color: #c1af82;
        }

        input[type="radio"]:checked::after {
          background-color: #c1af82;
        }
      }
    }

    .btn-wrapper {
      .left-btn,
      .right-btn {
        width: 100%;
        white-space: nowrap;
      }
      .right-btn {
        margin-left: 8px;
      }
    }
  }
}
