.wrapper-new-navbar {
  padding: 1.5rem 1rem;
  background: #252526;
  height: 100vh;
  width: 12.063rem;
  position: fixed;
  top: 0;
  left: 0;
}

.wrapper-menus {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  width: 100%;
}

.wrapper-content-open {
  width: 100%;
  height: calc(100% - 1.5rem);
  overflow-y: auto;
  overflow-x: hidden;
  scrollbar-width: none;
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    display: none;
  }

  .logo {
    height: 1.5rem;
    object-fit: contain;
    margin-bottom: 4rem;
  }

  .wrapper-c21-logo {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 4rem;

    .c21-logo {
      width: 2rem;
      height: 2rem;
      object-fit: contain;
    }
  }
}

.wrapper-outer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1.5rem;
  background: #252526;
  width: 100%;
  padding: 0.75rem;
  border-radius: 0.375rem;
  cursor: pointer;

  .wrapper-menu {
    display: flex;
    align-items: center;
    border-radius: 0.375rem;
  }

  .icon {
    img,
    svg {
      width: 1.125rem;
      height: 1.125rem;
      object-fit: contain;
    }
  }

  .label {
    font-family: "Inter";
    font-size: 0.813rem;
    font-weight: 600;
    line-height: 1.5rem;
    color: #e6e7e8;
    margin-left: 0.5rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 5.188rem;
  }

  .counter {
    width: 0.875;
    height: 0.875;
    object-fit: contain;
  }

  .red {
    color: #eb5757;
  }

  &.active {
    background: #c1af82;

    .label {
      color: #252526;
      font-weight: 600;
    }
  }
}

.keluar {
  margin-bottom: 0;
}

.wrapper-content-close {
  width: 100%;
  display: flex;
  justify-content: center;
  &.toggler-active {
    justify-content: flex-end;
  }
  .nav-toggler {
    cursor: pointer;
  }

  .toggler-inactive {
    transform: rotate(180deg);
  }
}
