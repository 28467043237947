.baris-ketiga{
  margin-bottom: 72px;
  padding: 0 40px;
  .baris-ketiga-calendar-wrapper{
   display: inline-block;
   margin-bottom: 24px;
  }
  .info-cards-wrapper{
    display: flex;
    align-items: center;

    .kmod-info-card{
      margin-right: 40px;
      &:last-child{
        margin-right: 0;
      }
    }
  }
}