.title {
  display: flex;
  margin-bottom: 16px;
}

.prefix {
  color: #9e9e9e;
}

.name {
  color: #c29d3e;
}
