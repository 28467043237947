.wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
  background: #fcfcfd;
  margin-bottom: 24px;
}
.wrapper-komisi {
  width: 100%;
  background: #ffffff;
  border: 0.5px solid #ececec;
  box-shadow: 0px 1px 3px rgba(16, 16, 16, 0.1), 0px 1px 2px rgba(16, 16, 16, 0.06);
  border-radius: 6px;
  padding: 24px 32px 16px;
}
.header {
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
  color: #101010;
  padding-bottom: 32px;
  border-bottom: 0.5px solid #ececec;
}

.commision-and-royalty {
  padding: 32px 0;
  border-bottom: 0.5px solid #ececec;
  display: flex;
  .jumlah-agent {
    margin-right: 88px;
    .label {
      font-weight: 400;
      font-size: 12px;
      line-height: 18px;
      color: #676767;
      margin-bottom: 8px;
    }
    .value {
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      color: #101010;
    }
  }
  .gcc {
    margin-right: 88px;
    .label {
      font-weight: 400;
      font-size: 12px;
      line-height: 18px;
      color: #676767;
      margin-bottom: 8px;
    }
    .value {
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: #219653;
      span {
        color: #9e9e9e;
      }
    }
  }
  .royalty {
    .label {
      font-weight: 400;
      font-size: 12px;
      line-height: 18px;
      color: #676767;
      margin-bottom: 8px;
      span {
        font-weight: 600;
        font-size: 12px;
        line-height: 18px;
        color: #101010;
      }
    }
    .value {
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: #9a6e00;
      span {
        color: #9e9e9e;
      }
    }
  }
}

.section {
  display: flex;
  padding: 32px 0;
  border-bottom: 0.5px solid #ececec;
  &:last-child {
    border-bottom: unset;
  }
}
.left,
.right {
  width: 50%;
  .top-label {
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: #676767;
    margin-bottom: 8px;
  }
  .top-value {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #101010;
    margin-bottom: 18px;
  }
}

.left {
  margin-right: 24px;
}

.right{
  border-bottom: unset;
}

.details-commision {
  background: #ffffff;
  .utama {
    padding-bottom: 16px;
  }
  .asisten {
    border-top: 1px solid #ececec;
    margin-bottom: 64px;
    padding-top: 16px;
  }
  .box {
    display: grid;
    grid-template-columns: repeat(3, calc(33% - 24px));
    justify-content: space-between;
  }

  .info-card {
    width: 100%;
    p:nth-child(1) {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #101010;
    }
    p:nth-child(2) {
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 18px;
      color: #676767;
    }
  }
}

.btn-wrapper {
  border-top: 1px solid #ececec;
  padding: 16px;
  padding-bottom: 32px;
  display: flex;
  width: 100%;
  justify-content: flex-end;
  .btn {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 16px;
    align-items: center;
    background: #ffffff;
    border: 1px solid #d9d9d9;
    box-shadow: 0px 1px 2px rgba(16, 16, 16, 0.05);
    border-radius: 6px;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #101010;

    &:active {
      transform: scale(0.9);
      transition: transform 0.1s ease-in-out;
    }
    &:disabled {
      transform: unset;
      color: #9e9e9e;
    }
    &.white {
      margin-right: 24px;
    }
    &.black {
      background: #101010;
      color: #ffffff;
    }
  }
}

.wrapper-komisi-nobroking {
  display: flex;
  flex-direction: column;
  align-items: center;
  .section {
    width: 694px;
  }
}

.left-nobroking {
  width: 100%;
}

.agent-dan-gcc {
  display: flex;
  justify-content: space-between;
  align-items: center;
  div {
    width: 50%;
  }
}
