.wrapper {
  padding: 40px 0;
  border-top: 1px solid #ececec;

  .header {
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 32px;
    color: #101010;
    margin-bottom: 24px;
  }

  .sub-header {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #494949;
    margin-bottom: 16px;
  }
}

.modal-wrapper {
  width: 100%;
  height: 100%;
  background-color: #101010;
  position: absolute;
  z-index: 42;
  overflow: auto;

  .modal-header {
    padding: 48px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    button {
      font-family: "Inter";
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      color: #ffffff;
      display: flex;
      align-items: center;
      svg {
        margin-right: 8px;
      }
    }

    .ukuran-banner {
      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: #9e9e9e;
      margin-bottom: 16px;

      span {
        font-family: "Inter";
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: #ffffff;
      }
    }
  }

  .pagination-wrapper {
    display: flex;
    justify-content: center;
    margin-bottom: 24px;
  }

  .modal-body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: calc(100vh - 182px - 120px);
    width: 100%;

    .wrapper-banner {
      width: 100vw;
    }
  }

  .modal-footer {
    padding: 48px 0;
    p {
      font-family: "Inter";
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      color: #ffffff;
      text-align: center;
      span {
        font-family: "Inter";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #676767;
      }
    }
  }
}

$OneMeter: 500px;
// $OneMeter: 3779.5275591px;

// Banner V1
.banner-tipe-1 {
  width: $OneMeter;
  height: $OneMeter;
  position: relative;
  margin: 0 auto;

  .banner {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .name {
    position: absolute;
    z-index: 1;
    top: 165px;
    color: #414141;
    font-size: 55px;
    font-family: "typold-bold";
    text-transform: uppercase;
    width: 100%;
    text-align: center;
  }

  .phone {
    position: absolute;
    z-index: 1;
    top: 225px;
    color: #414141;
    font-size: 55px;
    font-family: "typold-bold";
    width: 100%;
    text-align: center;
  }

  .company-name {
    position: absolute;
    z-index: 1;
    bottom: 13px;
    left: 182px;
    font-size: 25px;
    color: #fff;
    font-family: "typold-extended-medium";
  }

  .qr-code {
    position: absolute;
    z-index: 1;
    left: 0;
    width: 165px;
    height: 145px;
    bottom: 8px;
    object-fit: contain;
  }
}

.banner-tipe-2 {
  width: calc($OneMeter * 2);
  height: $OneMeter;
  position: relative;
  margin: 0 auto;

  .banner {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .name {
    position: absolute;
    z-index: 1;
    top: 220px;
    left: 42px;
    color: #414141;
    font-size: 80px;
    font-family: "typold-bold";
    text-transform: uppercase;
  }

  .phone {
    position: absolute;
    z-index: 1;
    top: 310px;
    left: 42px;
    color: #414141;
    font-size: 80px;
    font-family: "typold-bold";
  }

  .company-name {
    position: absolute;
    z-index: 1;
    bottom: 16px;
    left: 310px;
    font-size: 26px;
    color: #fff;
    font-family: "typold-extended-medium";
  }

  .qr-code {
    position: absolute;
    z-index: 1;
    right: 18px;
    width: 229px;
    height: 229px;
    bottom: 17px;
    object-fit: contain;
  }
}

.banner-tipe-3 {
  width: $OneMeter * 3;
  height: $OneMeter;
  position: relative;
  margin: 0 auto;

  .banner {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  .name {
    position: absolute;
    z-index: 1;
    top: 230px;
    left: 43px;
    color: #414141;
    font-size: 70px;
    font-family: "typold-bold";
    text-transform: uppercase;
  }

  .phone {
    position: absolute;
    z-index: 1;
    top: 305px;
    left: 43px;
    color: #414141;
    font-size: 90px;
    font-family: "typold-bold";
    text-transform: uppercase;
  }

  .company-name {
    position: absolute;
    z-index: 1;
    bottom: 16px;
    left: 310px;
    font-size: 24px;
    color: #fff;
    font-family: "typold-extended-medium";
  }

  .qr-code {
    position: absolute;
    z-index: 1;
    right: 18px;
    width: 229px;
    height: 231px;
    bottom: 18px;
    object-fit: contain;
  }
}

.banner-tipe-4 {
  width: $OneMeter * 3;
  height: calc($OneMeter + ($OneMeter/2));
  position: relative;
  margin: 0 auto;

  .banner {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  .name {
    position: absolute;
    z-index: 1;
    top: 210px;
    left: 46px;
    color: #414141;
    font-size: 93px;
    font-family: "typold-bold";
    text-transform: uppercase;
  }

  .phone {
    position: absolute;
    z-index: 1;
    top: 300px;
    left: 46px;
    color: #414141;
    font-size: 118px;
    font-family: "typold-bold";
  }

  .company-name {
    position: absolute;
    z-index: 1;
    bottom: 28px;
    left: 600px;
    font-size: 35px;
    color: #fff;
    font-family: "typold-extended-medium";
  }

  .qr-code {
    position: absolute;
    z-index: 1;
    width: 239px;
    height: 231px;
    bottom: 0;
    left: 0;
    object-fit: contain;
  }

  .profile-picture {
    position: absolute;
    z-index: 1;
    right: 8px;
    width: 341px;
    height: 600px;
    bottom: 0px;
    object-fit: contain;
  }
}

.banner-tipe-5 {
  width: $OneMeter * 4;
  height: $OneMeter;
  position: relative;
  margin: 0 auto;

  .banner {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  .name {
    position: absolute;
    z-index: 1;
    top: 220px;
    left: 42px;
    color: #414141;
    font-size: 70px;
    font-family: "typold-bold";
    text-transform: uppercase;
    letter-spacing: 7px;
  }

  .phone {
    position: absolute;
    z-index: 1;
    top: 270px;
    left: 38px;
    color: #414141;
    font-size: 120px;
    font-family: "typold-bold";
    text-transform: uppercase;
    letter-spacing: 15px;
  }

  .company-name {
    position: absolute;
    z-index: 1;
    bottom: 20px;
    left: 310px;
    font-size: 28px;
    color: #fff;
    font-family: "typold-extended-medium";
  }

  .qr-code {
    position: absolute;
    z-index: 1;
    right: 19px;
    width: 229px;
    height: 225px;
    bottom: 19px;
    object-fit: contain;
  }

  .profile-picture {
    position: absolute;
    z-index: 1;
    right: 290px;
    width: 250px;
    height: 410px;
    bottom: 48px;
    object-fit: contain;
  }
}

// Banner V2
.banner-tipe-1-v2 {
  width: $OneMeter;
  height: $OneMeter;
  position: relative;
  margin: 0 auto;

  .banner {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .name {
    position: absolute;
    z-index: 1;
    top: 165px;
    color: #414141;
    font-size: 55px;
    font-family: "typold-bold";
    text-transform: uppercase;
    width: 100%;
    text-align: center;
  }

  .phone {
    position: absolute;
    z-index: 1;
    top: 225px;
    color: #414141;
    font-size: 55px;
    font-family: "typold-bold";
    width: 100%;
    text-align: center;
  }

  .company-name {
    position: absolute;
    z-index: 1;
    bottom: 13px;
    left: 182px;
    font-size: 25px;
    color: #fff;
    font-family: "typold-bold";
  }

  .qr-code {
    position: absolute;
    z-index: 1;
    left: 0;
    width: 165px;
    height: 145px;
    bottom: 8px;
    object-fit: contain;
  }
}

.banner-tipe-2-v2 {
  width: calc($OneMeter * 2);
  height: $OneMeter;
  position: relative;
  margin: 0 auto;

  .banner {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .name {
    position: absolute;
    z-index: 1;
    top: 210px;
    left: 40px;
    color: #414141;
    font-size: 80px;
    font-family: "typold-bold";
    text-transform: uppercase;
  }

  .phone {
    position: absolute;
    z-index: 1;
    top: 300px;
    left: 40px;
    color: #414141;
    font-size: 80px;
    font-family: "typold-bold";
  }

  .company-name {
    position: absolute;
    z-index: 1;
    bottom: 16px;
    left: 305px;
    font-size: 26px;
    color: #fff;
    font-family: "typold-bold";
  }

  .qr-code {
    position: absolute;
    z-index: 1;
    right: 18px;
    width: 229px;
    height: 229px;
    bottom: 17px;
    object-fit: contain;
  }
}

.banner-tipe-3-v2 {
  width: $OneMeter * 3;
  height: $OneMeter;
  position: relative;
  margin: 0 auto;

  .banner {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  .name {
    position: absolute;
    z-index: 1;
    top: 210px;
    left: 43px;
    color: #414141;
    font-size: 80px;
    font-family: "typold-bold";
    text-transform: uppercase;
  }

  .phone {
    position: absolute;
    z-index: 1;
    top: 310px;
    left: 43px;
    color: #414141;
    font-size: 80px;
    font-family: "typold-bold";
    text-transform: uppercase;
  }

  .company-name {
    position: absolute;
    z-index: 1;
    bottom: 17px;
    left: 310px;
    font-size: 24px;
    color: #fff;
    font-family: "typold-bold";
  }

  .qr-code {
    position: absolute;
    z-index: 1;
    right: 18px;
    width: 229px;
    height: 231px;
    bottom: 18px;
    object-fit: contain;
  }
}

.banner-tipe-4-v2 {
  width: $OneMeter * 3;
  height: calc($OneMeter + ($OneMeter/2));
  position: relative;
  margin: 0 auto;

  .banner {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  .name {
    position: absolute;
    z-index: 1;
    top: 210px;
    left: 46px;
    color: #414141;
    font-size: 93px;
    font-family: "typold-bold";
    text-transform: uppercase;
  }

  .phone {
    position: absolute;
    z-index: 1;
    top: 310px;
    left: 46px;
    color: #414141;
    font-size: 118px;
    font-family: "typold-bold";
  }

  .company-name {
    position: absolute;
    z-index: 1;
    bottom: 30px;
    left: 600px;
    font-size: 35px;
    color: #fff;
    font-family: "typold-bold";
  }

  .qr-code {
    position: absolute;
    z-index: 1;
    width: 239px;
    height: 231px;
    bottom: 0;
    left: 0;
    object-fit: contain;
  }

  .profile-picture {
    position: absolute;
    z-index: 1;
    right: 8px;
    width: 341px;
    height: 600px;
    bottom: 0px;
    object-fit: contain;
  }
}

.banner-tipe-5-v2 {
  width: $OneMeter * 4;
  height: $OneMeter;
  position: relative;
  margin: 0 auto;

  .banner {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  .name {
    position: absolute;
    z-index: 1;
    top: 220px;
    left: 42px;
    color: #414141;
    font-size: 70px;
    font-family: "typold-bold";
    text-transform: uppercase;
    letter-spacing: 7px;
  }

  .phone {
    position: absolute;
    z-index: 1;
    top: 270px;
    left: 38px;
    color: #414141;
    font-size: 120px;
    font-family: "typold-bold";
    text-transform: uppercase;
    letter-spacing: 15px;
  }

  .company-name {
    position: absolute;
    z-index: 1;
    bottom: 22px;
    left: 315px;
    font-size: 28px;
    color: #fff;
    font-family: "typold-bold";
  }

  .qr-code {
    position: absolute;
    z-index: 1;
    right: 19px;
    width: 229px;
    height: 225px;
    bottom: 19px;
    object-fit: contain;
  }

  .profile-picture {
    position: absolute;
    z-index: 1;
    right: 290px;
    width: 250px;
    height: 410px;
    bottom: 0px;
    object-fit: contain;
  }
}

// Banner V3
.banner-tipe-6 {
  width: $OneMeter * 3;
  height: $OneMeter;
  position: relative;
  margin: 0 auto;

  .banner {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  .name {
    position: absolute;
    z-index: 1;
    top: 230px;
    left: 43px;
    color: #414141;
    font-size: 80px;
    font-family: "typold-bold";
    text-transform: uppercase;
  }

  .phone {
    position: absolute;
    z-index: 1;
    top: 310px;
    left: 43px;
    color: #414141;
    font-size: 80px;
    font-family: "typold-bold";
    text-transform: uppercase;
  }

  .company-name {
    position: absolute;
    z-index: 1;
    bottom: 16px;
    left: 304px;
    font-size: 24px;
    color: #fff;
    font-family: "typold-extended-medium";
  }

  .qr-code {
    display: none;
  }
}

.banner-tipe-7 {
  width: $OneMeter;
  height: $OneMeter;
  position: relative;
  margin: 0 auto;

  .banner {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .name {
    position: absolute;
    z-index: 1;
    top: 170px;
    color: #414141;
    font-size: 55px;
    font-family: "typold-bold";
    text-transform: uppercase;
    width: 100%;
    text-align: center;
  }

  .phone {
    position: absolute;
    z-index: 1;
    top: 230px;
    color: #414141;
    font-size: 55px;
    font-family: "typold-bold";
    width: 100%;
    text-align: center;
  }

  .company-name {
    position: absolute;
    z-index: 1;
    bottom: 45px;
    left: 252px;
    font-size: 25px;
    color: #fff;
    font-family: "typold-extended-medium";
  }

  .qr-code {
    display: none;
  }
}

.banner-tipe-8 {
  width: calc($OneMeter * 2);
  height: $OneMeter;
  position: relative;
  margin: 0 auto;

  .banner {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .name {
    position: absolute;
    z-index: 1;
    top: 230px;
    left: 42px;
    color: #414141;
    font-size: 80px;
    font-family: "typold-bold";
    text-transform: uppercase;
  }

  .phone {
    position: absolute;
    z-index: 1;
    top: 320px;
    left: 42px;
    color: #414141;
    font-size: 80px;
    font-family: "typold-bold";
  }

  .company-name {
    position: absolute;
    z-index: 1;
    bottom: 15px;
    left: 305px;
    font-size: 26px;
    color: #fff;
    font-family: "typold-extended-medium";
  }

  .qr-code {
    position: absolute;
    z-index: 1;
    right: 18px;
    width: 229px;
    height: 229px;
    bottom: 17px;
    object-fit: contain;
  }
}
