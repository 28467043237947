.kk-form-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 13px;
  .form {
    background: #ffffff;
    border: 1px solid #ececec;
    box-shadow: 0px 1px 3px rgba(16, 16, 16, 0.1), 0px 1px 2px rgba(16, 16, 16, 0.06);
    border-radius: 6px;
    min-width: 516px;
    .kkf-header {
      padding: 24px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .title {
        font-family: "Inter";
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 28px;
        color: #101010;
      }
      .ctbc-icon {
        width: 220px;
        height: 28px;
      }
    }

    .kkf-body {
      padding: 24px;
      padding-top: 0;

      .kkf-agents-wrapper {
        margin-bottom: 32px;
        .kkf-agent-selected {
          border: 1px solid #d9d9d9;
          box-shadow: inset 0px 1px 2px rgba(16, 16, 16, 0.05);
          border-radius: 6px;
          padding: 10px 16px;
          cursor: pointer;
          span {
            font-family: "Inter";
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            color: #101010;
            display: flex;
            justify-content: space-between;
            align-items: center;
            .arrow-bottom {
              width: 16px;
              height: 16px;
            }
          }
          .placeholder {
            font-family: "Inter";
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            color: #9e9e9e;
            width: 100%;
          }
        }

        .kkfa-search-wrapper {
          margin-top: 8px;
          padding: 24px;
          border: 1px solid #d9d9d9;
          box-shadow: 0px 1px 2px rgba(16, 16, 16, 0.06);
          border-radius: 6px;

          .kkfa-result-box {
            overflow-y: auto;
            height: 150px;

            .agent-name {
              width: 100%;
              display: flex;
              justify-content: flex-start;
              padding: 16px;
              font-size: $fontM;
              line-height: $ln-height-M;
              color: $blackV1;
              border-bottom: 1px solid $greyV4;
              cursor: pointer;
            }
            .selected {
              background: $greyV4;
              border: none;
            }
          }
        }
      }

      .kkf-textarea {
        .textarea-wrapper {
          textarea {
            height: 72px;
          }
        }
      }

      ._field {
        margin-bottom: 32px;
      }

      .kkf-radio-wrapper {
        .label {
          font-family: "Inter";
          font-style: normal;
          font-weight: 500;
          font-size: 12px;
          line-height: 18px;
          color: #676767;
          margin-bottom: 6px;
        }
        .kkf-radio {
          display: flex;
          align-items: center;
          button {
            display: flex;
            align-items: center;
            svg {
              width: 16px;
              height: 16px;
              margin-right: 8px;
            }
            span {
              font-family: "Inter";
              font-style: normal;
              font-weight: 400;
              font-size: 14px;
              line-height: 20px;
              color: #101010;
            }

            &:first-child {
              margin-right: 40px;
            }
          }
        }
      }
    }

    .kkf-footer {
      padding: 24px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .info-ctbc {
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;
        span {
          font-family: "Inter";
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 20px;
          text-decoration-line: underline;
          color: #101010;
        }
        .info-icon {
          width: 18px;
          height: 18px;
          margin-right: 8px;
        }
      }

      .btn-kirim {
        padding: 10px 16px;
        background: #101010;
        box-shadow: 0px 1px 2px rgba(16, 16, 16, 0.05);
        border-radius: 6px;
        span {
          font-family: "Inter";
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 20px;
          color: #ffffff;
        }

        .plane-icon {
          width: 14px;
          height: 14px;
          margin-left: 8px;
        }

        &.disable {
          background: #d9d9d9;
          box-shadow: 0px 1px 2px rgba(16, 16, 16, 0.05);
          border-radius: 6px;
        }
      }
    }
  }
}
