#modal-pilih-listing {
  .modal-content {
    width: 100%;
    max-width: 807px;
    background: #fff;
    padding: 32px;
    border: 0.5px solid #ececec;
    box-shadow: 0px 1px 3px rgba(16, 16, 16, 0.1), 0px 1px 2px rgba(16, 16, 16, 0.06);
    border-radius: 6px;
    display: flex;

    .listing-wrapper {
      display: flex;
      .left-content {
        padding-right: 24px;
        border-right: 1px solid #ececec;
        width: 100%;
        .img-wrapper {
          width: 389px;
          height: 373px;
          margin-bottom: 24px;
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
        .lc-title {
          @include text-18;
          margin-bottom: 8px;
          font-weight: 600;
        }
        .lc-info {
          @include text-14;
          font-weight: 400;
          color: #676767;
        }
      }

      .right-content {
        width: 100%;
        padding-left: 24px;
        .close-btn-wrapper {
          @include flex-col-end;
          margin-bottom: 24px;
          .close-icon {
            width: 16px;
            height: 16px;
            cursor: pointer;
          }
        }

        .label-wrapper {
          display: flex;
          align-items: center;
          white-space: nowrap;
          span {
            padding: 4px 8px;
            &:nth-child(1) {
              background: #d3ffe6;
              border-radius: 4px;
              margin-right: 8px;
              &.disewakan {
                background: #FFEEBA;
                color: #624A00;
              }
            }

            &:nth-child(2) {
              border: 1px solid #ececec;
              box-shadow: 0px 1px 2px rgba(16, 16, 16, 0.06);
              border-radius: 6px;
            }
          }
        }

        .button-wrapper{
          margin-top: 174px;
          .batal-btn, 
          .lanjutkan-btn{
            @include btn-black;
            width: 100%;
          }

          .batal-btn{
            margin-right: 16px;
            background: #fff;
            border: 1px solid #D9D9D9;
            span{
              color: $blackV1;
            }
          }
        }
      }
    }
  }
}
