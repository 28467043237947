.text-large {
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 32px;
  color: #101010;
  .required {
    color: #af0000;
  }
}
