.dashboard-leads-wrapper {
  padding: 32px;
  border-bottom: 0.5px solid #ececec;
  box-shadow: 0px 1px 3px rgba(16, 16, 16, 0.1), 0px 1px 2px rgba(16, 16, 16, 0.06);
  p {
    font-weight: 500;
    font-size: 30px;
    line-height: 38px;
    color: #101010;
  }
}
