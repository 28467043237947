.data-agent-form-wrapper {
  height: 100vh;
  overflow-y: auto;
  .daf-header {
    @include flex-between;
    @include box-v2;
    padding: 32px;
    .back-main-page-btn {
      width: auto;
      @include btn-border;
    }
  }

  .daf-body {
    padding: 55px 32px;
    min-height: calc(100vh - 115px - 117px);

    .form-wrapper {
      @include box-v2;

      .divider {
        border-color: #d9d9d9;
        border-width: 0.5px;
      }

      .form-header {
        padding: 24px 32px 16px 32px;
        .__form-header {
          p {
            &:nth-child(1) {
              @include text-24;
            }
            &:nth-child(2) {
              @include text-14($color: #676767);
            }
          }
        }
      }

      .form-container {
        padding-top: 40px;
        padding-bottom: 40px;
        display: flex;
        .print-info {
          display: flex;
          margin-top: 4px;
          img {
            width: 12px;
            height: 12px;
            object-fit: contain;
            margin-right: 4px;
            margin-top: 2px;
          }
          span {
            @include text-10($color: #676767);
          }
        }

        .left-form-wrapper,
        .right-form-wrapper {
          padding: 0 80px;
          width: 100%;
          .top-form {
            display: flex;

            .form-label {
              width: 30%;
              margin-right: 64px;
              white-space: nowrap;
              p {
                &:nth-child(1) {
                  @include text-14;
                  margin-bottom: 8px;
                }
                &:nth-child(2) {
                  @include text-10($color: #af0000);
                }
              }
            }

            .form-input {
              width: 70%;
            }
          }

          .form-footer {
            display: flex;
            align-items: center;
            justify-content: center;
            .plus-btn {
              width: auto;
              background: $blackV1;
              margin-left: 16px;
              svg {
                margin: 0;
              }
              &.disabled {
                background: #ececec;
              }
            }
          }
        }

        .left-form-wrapper {
          border-right: 1px solid #d9d9d9;
        }
      }
    }
  }

  .daf-footer {
    @include box-v2;
    @include flex-col-end;
    border-top: 0.5px solid #ececec;
    padding: 32px;
    .next-to-form {
      @include btn-black;
      width: auto;
      flex-direction: row-reverse;

      svg {
        @include svg-v1;
        margin: 0;
        margin-left: 14px;
      }
    }
  }
}
