.navbar {
  width: 80px;
  background: $blackV1;
  height: 100vh;
  // overflow-x: auto;
  // -ms-overflow-style: none; /* Internet Explorer 10+ */
  // scrollbar-width: none; /* Firefox */
  // &::-webkit-scrollbar {
  //   display: none; /* Safari and Chrome */
  // }
  position: relative;

  //Nav header
  .nav-header {
    margin-top: 40px;
    margin-bottom: 88px;
    width: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  //Nav contents
  .nav-options {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: calc(100vh - 176px);
    // overflow-x: auto;
    // -ms-overflow-style: none; /* Internet Explorer 10+ */
    // scrollbar-width: none; /* Firefox */
    // &::-webkit-scrollbar {
    //   display: none; /* Safari and Chrome */
    // }

    position: absolute;
    width: 80px;
    top: 176px;
  }

  //Nav options/tab
  .nav-body {
    .tab-container {
      margin-left: 16px;
      margin-bottom: 40px;
      .image-container {
        position: relative;
        &:hover::after {
          position: absolute;
          white-space: nowrap;
          padding: 10px 16px;
          background: white;
          color: $blackV1;
          content: attr(tooltip);
          border-radius: 4px;
          top: -48px;
          left: 0;
          border-bottom: 0.5px solid #ececec;
          box-shadow: 0px 4px 3px rgb(16 16 16 / 10%), 0px 1px 2px rgb(16 16 16 / 6%);
        }

        &:hover::before {
          border: solid;
          border-color: #fff transparent;
          border-width: 12px 6px 0 6px;
          content: "";
          bottom: 30px;
          position: absolute;
          bottom: 48px;
        }

        width: 48px;
        height: 48px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: $blackV1;
        cursor: pointer;

        .nav-icon {
          width: 24px;
          height: 24px;
          path {
            stroke: #ffffff;
          }
        }

        &.active {
          background: $yellowV1;
          cursor: normal;
          border-radius: 6px;
          .nav-icon {
            path {
              stroke: $blackV1;
            }
          }
        }
      }
    }
  }

  //Nav footer
  .nav-footer {
    padding: 0 16px;
    margin-bottom: 48px;

    .footer-icon-container {
      width: 100%;
      margin-bottom: 40px;
      display: flex;
      justify-content: center;
      align-items: center;

      .footer-icon {
        width: 24px;
        height: 24px;
      }
    }
    .last-item {
      margin-bottom: 0;
      margin-top: 40px;
    }
  }
}
