.kk-info-wrapper {
  height: 100vh;
  width: 679.5px;
  animation: kk-slidein 0.3s;
  box-shadow: 0px 1px 2px rgba(16, 16, 16, 0.06);
  border-left: 1px solid #ececec;
  padding: 40px 32px;
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 60px;
    p {
      font-family: "Inter";
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 30px;
      color: #101010;
    }
    .arrow-close {
      width: 24px;
      height: 24px;
      cursor: pointer;
    }
  }

  .image-container {
    .img-wrapper {
      display: flex;
      justify-content: space-between;
      margin-bottom: 24px;
      img {
        cursor: pointer;
        width: 100%;
        height: 339px;
        object-fit: contain;
      }

      &.double {
        img {
          width: 295.75px;
          height: 282.44px;
          object-fit: contain;
        }
      }
    }
  }

  .kk-info-modal-content {
    width: 100vw;
    height: 100vh;
    background-color: rgb(0, 0, 0); /* Fallback color */
    background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
    position: fixed;
    top: 0;
    left: 0;
    .kk-info-slider-header {
      display: flex;
      justify-content: flex-end;
      .close-icon {
        width: 40px;
        height: 40px;
        cursor: pointer;
        path {
          stroke: white;
        }
      }
    }

    .kk-info-slider-content {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 100%;
      .slider-image-wrapper {
        img {
          width: 706px;
          height: 712px;
          object-fit: contain;
        }
      }
      .slider-icon-wrapper {
        padding: 55px 16px;
        &.prev {
          background: #ffffff;
          border-radius: 0px 6px 6px 0px;
          .prev-icon {
            fill: black;

            path {
              fill: black;
            }
          }
        }
        &.next {
          background: #101010;
          border-radius: 6px 0px 0px 6px;
        }
      }
    }
  }
}

@keyframes kk-slidein {
  from {
    width: 0;
  }
  to {
    width: 679.5px;
  }
}
