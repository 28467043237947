.letter-avatar{
  width: 48px;
  height: 48px;
  border: 1px solid $greyV1;
  border-radius: 100px;
  display: flex;
  justify-content: center;
  align-items: center;

  span{
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 32px;
    color: #fff;
  }
}