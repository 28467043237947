.gambaran-umum-wrapper {
  width: 100%;

  .properti-header {
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    color: #101010;
    margin-bottom: 8px;
  }
  .properti-location {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #494949;
    margin-bottom: 24px;
  }
  .properti-labels-wrapper {
    display: flex;
    align-items: center;
    margin-bottom: 42px;
    .properti-label {
      background: #ffffff;
      border: 1px solid #494949;
      border-radius: 8px;
      padding: 1px 16px;
      color: #101010;
      margin-right: 8px;
      font-family: "Inter";
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 18px;
      &:first-child {
        background: #101010;
        color: #ffffff;
        border: unset;
      }
      &:nth-child(2) {
        border: unset;
        color: #ffffff;
        background: #0d814a;
      }
    }
  }

  .images-wrapper {
    width: 100%;
    display: flex;
    margin-bottom: 16px;
    justify-content: center;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    .properti-main-image {
      width: 50%;
      height: 263px;
      margin-right: 16px;
      position: relative;
      .properti-image-tag {
        position: absolute;
        top: 16px;
        left: 16px;
        background: rgba(16, 16, 16, 0.8);
        border-radius: 8px;
        padding: 4px 16px;
        text-align: center;
        font-family: "Inter";
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        color: #ffffff;
      }
      img {
        width: 100%;
        height: 100%;
        border-radius: 16px 0px 0px 16px;
        object-fit: cover;
      }
    }
    .properti-secondary-image {
      width: 50%;
      .main-image {
        position: relative;
        .properti-image-tag {
          position: absolute;
          top: 16px;
          left: 12px;
          background: rgba(16, 16, 16, 0.8);
          border-radius: 8px;
          padding: 4px 16px;
          text-align: center;
          font-family: "Inter";
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 18px;
          color: #ffffff;
        }
      }
      .secondary-top {
        display: flex;
        .main-image {
          width: 50%;
          height: 123.5px;
          margin-right: 16px;
          margin-bottom: 16px;
          &:last-child {
            margin-right: 0;
            img {
              border-radius: 0px 16px 0px 0px;
              width: 100%;
              height: 100%;
            }
          }
        }
      }
      .secondary-bottom {
        display: flex;
        .main-image {
          width: 50%;
          height: 123.5px;
          margin-right: 16px;
          &:last-child {
            margin-right: 0;
            img {
              border-radius: 0px 0px 16px 0px;
              width: 100%;
              height: 100%;
            }
          }
        }
      }
    }
  }

  .properti-tampilkan-semua-btn {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 40px;
    border: 1px solid #101010;
    box-shadow: 0px 1px 2px rgba(16, 16, 16, 0.05);
    border-radius: 6px;
    background: #ffffff;
    padding: 10px;
    span {
      font-family: "Inter";
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      color: #101010;
    }
  }

  .properti-price {
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    line-height: 38px;
    background: linear-gradient(90deg, #c1af82 -6.5%, #75612f 50%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    margin-bottom: 8px;
  }

  .properti-room-info {
    display: flex;
    align-items: center;
    margin-bottom: 40px;
    padding-bottom: 40px;
    border-bottom: 1px solid #ececec;
    span {
      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: #494949;
    }
  }
}
