.properti-detail-fasilitas-sekitar {
  padding-bottom: 40px;
  margin-bottom: 40px;
  border-bottom: 1px solid #ececec;
  .fasilitas-sekitar-header {
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 32px;
    color: #101010;
    margin-bottom: 24px;
  }
  .fasilitas-sekitar-content {
    display: flex;
    flex-wrap: wrap;
    p {
      width: 50%;
      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #494949;
      margin-bottom: 16px;
      display: flex;
      align-items: center;
      img {
        margin-right: 4px;
        width: 4px;
        height: 4px;
      }
    }
  }
}
