@import "~styles/_variables.scss";

.wrapper-content {
  width: 100%;
  max-width: calc(100vw - 282px - 282px);
  background: #fff;
  padding: 32px;
  border: 0.5px solid #ececec;
  box-shadow: 0px 1px 3px rgba(16, 16, 16, 0.1), 0px 1px 2px rgba(16, 16, 16, 0.06);
  border-radius: 6px;
  display: flex;
  z-index: calc($zIndex + 2);

  .left {
    width: 50%;
    padding-right: 24px;
    border-right: 1px solid #ececec;
    .image {
      height: 373px;
      width: 100%;
      margin-bottom: 24px;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 6px;
      }
    }
    .left-title {
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 28px;
      color: #101010;
      margin-bottom: 8px;
    }
    .info {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #676767;
    }
  }
  .right {
    width: 50%;
    padding-left: 24px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .wrapper-close {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      margin-bottom: 24px;
      cursor: pointer;
      svg {
        width: 16px;
        height: 16px;
        cursor: pointer;
        &:active {
          transform: scale(0.9);
          transition: transform 0.1s ease-in-out;
        }
      }
    }

    .right-title {
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 18px;
      color: #9e9e9e;
      margin-bottom: 16px;
    }
    .price {
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      line-height: 28px;
      color: #9a6e00;
      margin-bottom: 16px;
      span {
        color: #101010;
      }
    }
    .title {
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: #101010;
      margin-bottom: 8px;
    }
    .street {
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 18px;
      color: #676767;
      margin-bottom: 18px;
    }
    .label-wrapper {
      display: flex;
      align-items: center;
      margin-bottom: 40px;
      p {
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 18px;
        color: #ffffff;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 2px 16px;
        border-radius: 24px;
      }
      .jual {
        background: #0d814a;
      }
      .sewa {
        background: #fec601;
        color: #101010;
      }
      .id {
        margin-left: 16px;
        border: 1px solid #9e9e9e;
        background: #ffffff;
        color: #101010;
      }
      .hash {
        color: #9e9e9e;
      }
    }

    .agent-info {
      margin-bottom: 16px;
      .nama {
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        color: #676767;
        margin-bottom: 4px;
      }
      .value {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: #101010;
      }
    }

    .btn-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;

      button {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 10px 16px;
        box-shadow: 0px 1px 2px rgba(16, 16, 16, 0.05);
        border-radius: 6px;
        width: 100%;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        cursor: pointer;
        &:active {
          transform: scale(0.9);
          transition: transform 0.1s ease-in-out;
        }
        &:disabled {
          transform: unset;
        }
        &.batal {
          border: 1px solid #d9d9d9;
          color: #101010;
          margin-right: 24px;
        }
        &.lanjutkan {
          color: #ffffff;
          background: #101010;
        }
      }
    }
  }
}
