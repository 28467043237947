#leads-dashboard-select-agent {
  .modal-content {
    width: 100%;
    max-width: 807px;
    background: #fff;
    padding: 32px;
    border: 0.5px solid #ececec;
    box-shadow: 0px 1px 3px rgba(16, 16, 16, 0.1), 0px 1px 2px rgba(16, 16, 16, 0.06);
    border-radius: 6px;
    display: flex;

    .search-section,
    .list-section {
      width: 100%;
    }

    .search-section {
      border-right: 1px solid #ececec;
      padding-right: 32px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      .search-suggestion {
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        color: #494949;
        margin-bottom: 8px;
        span {
          color: #101010;
        }
      }
      .search-header {
        color: #101010;
        font-weight: 600;
        font-size: 24px;
        margin-bottom: 24px;
      }
      .search-agent {
        margin-bottom: 8px;
      }
      .search-example {
        margin-bottom: 24px;
        color: #676767;
        font-size: 12px;
        line-height: 18px;
      }

      .divider {
        border: 1px solid #ececec;
        margin-bottom: 24px;
      }
      .search-info {
        font-size: 12px;
        line-height: 18px;
        margin-bottom: 8px;
        color: #676767;
      }
      .search-info-large {
        font-size: 14px;
        line-height: 20px;
        color: #9e9e9e;
        font-weight: 600;
      }
      .selected-agent {
        color: #101010;
      }
    }

    .list-section {
      padding-left: 32px;
      .close-btn-wrapper {
        display: flex;
        justify-content: flex-end;
        margin-bottom: 8px;
        svg {
          width: 16px;
          height: 16px;
          cursor: pointer;
        }
      }
      .list-header {
        font-size: 12px;
        line-height: 18px;
        color: #676767;
        margin-bottom: 32px;
      }
      .list-content {
        overflow-y: auto;
        height: 356px;
        margin-bottom: 32px;

        .agent-name {
          width: 100%;
          display: flex;
          justify-content: flex-start;
          padding: 16px;
          font-size: 14px;
          line-height: 20px;
          color: #101010;
          border-bottom: 1px solid #ececec;
          cursor: pointer;
        }
        .selected {
          background: #ececec;
          border: none;
        }
      }
      .btn-wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
      .choose-agent-btn {
        width: 100%;
        &:first-child {
          margin-right: 16px;
          background: #ffffff;
          border: 1px solid #d9d9d9;
          box-shadow: 0px 1px 2px rgba(16, 16, 16, 0.05);
          border-radius: 6px;
          span {
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;
            color: #101010;
          }
        }
      }
    }
  }
}
