.wrapper {
  width: 100%;
  .name {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: #676767;
    margin-bottom: 8px;
  }
  .value {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #101010;
  }
}