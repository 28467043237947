.detail-listing-wrapper {
  width: 45%;
  font-family: "Inter";
  padding: 24px;
  min-height: 100vh;
  // overflow-y: auto;
  //Global usage
  background: #ffffff;
  &.hide{
    visibility: hidden;
  }
  .detail-listing-t1 {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    color: #9e9e9e;
  }
  .detail-listing-point {
    display: flex;
    align-items: center;
  }
}

#modal-active-inactive {
  width: 100%;
  max-width: 422px;
  min-height:  262px;
  background: #fff;
  padding: 16px;
  border: 0.5px solid #ececec;
  box-shadow:
    0px 1px 3px rgba(16, 16, 16, 0.1),
    0px 1px 2px rgba(16, 16, 16, 0.06);
  border-radius: 6px;
  z-index: 100001;
  .btn-wrapper {
    display: flex;
    justify-content: flex-end;
    // margin-right: 20px;
  }
  .modal-content {
    padding-top: 16px;
    padding-bottom: 16px;
    padding-left: 24px;
    padding-right: 24px;
    margin-bottom: 16px;
    margin-top: 16px;
  }
  .text {
    color: var(--neutral-100, #101010);
    /* Text xl/Medium */
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 30px; /* 150% */
    padding-bottom: 8px;
  }
  .subtext {
    color: var(--neutral-80, #676767);
    /* Text sm/Regular */
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
  }

  .border-grey {
    background-color: #ECECEC;
    min-height: 1px;
    margin-bottom: 16px;
  }
  .wrapper-btn {
    display: flex;
    justify-content: center;
    .custom-btn {
      span {
        font-family: "Inter";
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
      }
    }
    .left-btn{
      margin-right: 24px;
      padding-right: 16px;
      padding-Left: 16px;
      background-color: #fff;
      colo: #101010;
    }
  }
}
.btn-cancel {
  display: flex;
  padding: 10px 16px;
  justify-content: center;
  align-items: center;
  color: #101010;
  cursor: pointer;
  /* Text sm/Medium */
  font-family: "Inter";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */

  border-radius: 6px;
  border: 1px solid #d9d9d9;
  background:#fff;
  box-shadow: 0px 1px 2px 0px rgba(16, 16, 16, 0.05);
  margin-right: 24px;
  max-width: 66px;
}
.btn-yes {
  display: flex;
  padding: 10px 16px;
  justify-content: center;
  align-items: center;
  color: #FFFFFF;
  cursor: pointer;
  /* Text sm/Medium */
  font-family: "Inter";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
  max-width: 181px;
  border-radius: 6px;
  // border: 1px solid #d9d9d9;
  background: #101010;
  box-shadow: 0px 1px 2px 0px rgba(16, 16, 16, 0.05);
}

.btn-yes-delete {
  display: flex;
  padding: 10px 16px;
  justify-content: center;
  align-items: center;
  color: #FFFFFF;
  cursor: pointer;
  /* Text sm/Medium */
  font-family: "Inter";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
  max-width: 181px;
  border-radius: 6px;
  // border: 1px solid #d9d9d9;
  background: #FA0000;
  box-shadow: 0px 1px 2px 0px rgba(16, 16, 16, 0.05);
}