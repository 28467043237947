@import "_variables.scss";

*,
body,
p,
span,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: $font-family;
  color: $blackV1;
  text-decoration: none;
}

select,
input,
textarea {
  background: inherit;
  color: inherit;
  border: none;
  &:focus-visible {
    outline: 0;
  }
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

button {
  border: none;
  background: inherit;
}

@import "./globalStyles.scss";
@import "./reuseableStyles.scss";

#dashboard {
  width: 100%;
  display: flex;
  .pages-container {
    width: 100%;
    background: #ffffff;
  }
}

// Components
@import "./components/index.scss";

// Navigations
@import "./navigation/index.scss";

// Pages
@import "./pages/index.scss";
