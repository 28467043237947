.wrapper {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  svg {
    width: 60px;
    height: 60px;
    margin-bottom: 16px;
  }

  .title {
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    text-align: center;
    color: #101010;
    margin-bottom: 16px;
  }

  .sub-title {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    text-align: center;
    color: #676767;
    margin-bottom: 16px;
    padding-bottom: 16px;
    border-bottom: 1px solid #ececec;
  }

  .btn {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px 16px;
    background: #101010;
    box-shadow: 0px 1px 2px rgba(16, 16, 16, 0.05);
    border-radius: 6px;

    span {
      font-family: "Inter";
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      color: #ffffff;
    }
  }
}
