@font-face {
  font-family: "SF Pro Display";
  src: url(../fonts/SFPRODISPLAYREGULAR.OTF) format("truetype");
}

@font-face {
  font-family: "SF Pro Display Medium";
  src: url(../fonts/SFProDisplay-Medium.ttf) format("truetype");
}

@font-face {
  font-family: "SF Pro Display SemiBold";
  src: url(../fonts/SFProDisplay-Semibold.ttf) format("truetype");
}

@font-face {
  font-family: "SF Pro Display Bold";
  src: url(../fonts/SFProDisplay-Bold.ttf) format("truetype");
}

