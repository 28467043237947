#modal-filter-lt {
  .modal-content {
    width: 100%;
    max-width: 487px;
    background: #fff;
    padding: 32px;
    border: 0.5px solid #ececec;
    box-shadow: 0px 1px 3px rgba(16, 16, 16, 0.1), 0px 1px 2px rgba(16, 16, 16, 0.06);
    border-radius: 6px;

    .divider {
      border: 1px solid #ececec;
      margin-top: 32px;
      margin-bottom: 32px;
    }

    .close-wrapper {
      display: flex;
      justify-content: flex-end;
    }
    .title {
      color: $blackV1;
    }
    p {
      color: #676767;
    }

    .nominal-transaksi {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .dash {
        margin: 0 16px;
      }
    }

    .btn-wrapper {
      display: flex;
      justify-content: center;
      .left-btn,
      .right-btn {
        width: 100%;
        white-space: nowrap;
        max-width: 101px;
      }
      .left-btn {
        @include btn-border;
        background: #fff;
        margin-right: 24px;
        span {
          color: $blackV1;
        }
      }
      .right-btn {
        margin-left: 8px;
      }
    }
  }
}

