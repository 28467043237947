//Reuseable animation
@keyframes rotating {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.rotate-infinite {
  -webkit-animation: rotating 2s linear infinite;
  -moz-animation: rotating 2s linear infinite;
  -ms-animation: rotating 2s linear infinite;
  -o-animation: rotating 2s linear infinite;
  animation: rotating 2s linear infinite;
}

//Reuseabe class
.btn-active {
  &:active {
    background: #fff;
    border: 1px solid $greyV1;
    color: $blackV1;
    span {
      color: inherit;
    }
  }
}

.nav-content-wrapper {
  width: calc(100vw - 193px);
  margin-left: 193px;
}

.hide-border {
  border: none;
}

.modal-backdrop {
  background: rgb(0, 0, 0, 0.8);
}
