@import "../../../../../styles/fonts";

.kmod-info-card {
  padding: 24px;
  background: #141414;
  border: 1px solid #1a1a1a;
  border-radius: 16px;
  width: 100%;
  height: 162px;

  .kmod-info-card-icon {
    width: 24px;
    height: 24px;
    margin-bottom: 8px;
  }

  .kmod-info-tag {
    font-family: "SF Pro Display";
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 18px;
    color: #a7a7a7;
    margin-bottom: 4px;
  }
  .kmod-info-point {
    font-family: "SF Pro Display";
    font-style: normal;
    font-weight: 400;
    font-size: 48px;
    line-height: 60px;
    color: #e9eef6;
    margin: 0;
  }

}
.kmod-info-loader-wrapper {
  padding: 24px;
  background: #141414;
  border: 1px solid #1a1a1a;
  border-radius: 16px;
  width: 100%;
  height: 162px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 40px;
  &:last-child{
    margin: 0;
  }
}
