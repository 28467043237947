.wrapper{
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5); 
  .banner-wrapper{
    width: 80%;
    height: 312px;
    max-width: 1440px;
    position: relative;
    img{
      width: 100%;
      height: 100%;
      object-fit: contain;
      cursor: pointer;
    }

    .close-icon{
      position: absolute;
      bottom: -50px;
      right: 50%;
      width: 32px;
      height: 32px;
      object-fit: contain;
      cursor: pointer;
    }
  }
}