#modal-map {
  .modal-content {
    width: 100%;
    max-width: 996px;
    background: #fff;
    padding: 32px;
    border: 0.5px solid #ececec;
    box-shadow: 0px 1px 3px rgba(16, 16, 16, 0.1), 0px 1px 2px rgba(16, 16, 16, 0.06);
    border-radius: 6px;
    display: flex;

    .input-section {
      border-right: 1px solid #ececec;
      padding-right: 32px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 100%;
      max-width: 332px;
      .search-header {
        color: $blackV1;
        font-weight: $font-bolder;
        font-size: $fontXL;
        margin-bottom: 24px;
      }
      .search-location {
        margin-bottom: 8px;
      }
      .search-info {
        margin-bottom: 38px;
        color: $greyV1;
        font-size: $fontXS;
        line-height: $ln-height-XS;
      }

      .divider {
        border: 1px solid $greyV4;
        margin-bottom: 24px;
      }

      .lokasi-header {
        font-size: $fontS;
        line-height: $ln-height-S;
        margin-bottom: 8px;
        color: $greyV1;
      }

      .selected-location {
        font-size: $fontM;
        line-height: $ln-height-M;
        color: $blackV1;
        font-weight: $font-bolder;
        margin-bottom: 24px;
      }
    }

    .map-section {
      width: 100%;
      padding-left: 32px;
      .close-btn-wrapper {
        display: flex;
        justify-content: flex-end;
        margin-bottom: 8px;
        svg {
          width: 16px;
          height: 16px;
          cursor: pointer;
        }
      }
      .map-guide-text {
        font-size: $fontS;
        line-height: $ln-height-S;
        color: $greyV1;
        margin-bottom: 38px;
      }
      .c-google-map {
        width: 100%;
        height: 100%;
      }
    }
  }
}
