.title {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}

.input-wrapper {
  .info {
    align-self: center;
    margin-top: 24px;
    svg {
      width: 18px;
      height: 18px;
      margin-right: 8px;
    }
  }
}
