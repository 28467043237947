.wrapper {
  background: #fcfcfd;
  padding: 16px 36px;
}

.content {
  background: #ffffff;
  border: 0.5px solid #f5f5f5;
  box-shadow: 0px 1px 3px rgba(16, 16, 16, 0.1), 0px 1px 2px rgba(16, 16, 16, 0.06);
  border-radius: 6px;
  padding: 24px 32px;
}

.deskripsi {
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
  color: #101010;
  margin-bottom: 8px;
}

.address-and-labels {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 32px;
  border-bottom: 1px solid #f5f5f5;

  .address {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #676767;
  }

  .labels {
    display: flex;
    align-items: center;

    span {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 2px 16px;
      border-radius: 100px;
      background: #ececec;
      font-weight: 400;
      font-size: 12px;
      line-height: 18px;
      color: #101010;
      margin-right: 8px;
      &:last-child {
        margin-right: 0;
      }
    }
    .property-type {
      background: #101010;
      color: #ffffff;
    }
  }
}

.btn-wrapper{
  display: flex;
  justify-content: flex-end;
}
