.image-options-wrapper {
  width: 150px;
  .select-position {
    width: 70px;
    .select-wrapper {
      background: #101010;
      box-shadow: inset 0px 1px 2px rgba(16, 16, 16, 0.05);
      border-radius: 6px 6px 0px 0px;
      .select-position{
        color: white;
      }
    }
  }
  .image-with-close {
    width: 100%;
    height: 150px;
    position: relative;
    box-shadow: $bx-shadow-v1;
    border: 1px solid $greyV4;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
    margin-bottom: 8px;
    .cross {
      width: 20px;
      height: 20px;
      position: absolute;
      top: -7px;
      right: -7px;
      cursor: pointer;
    }

    .uploaded-image {
      width: 100%;
      height: 100%;
      object-fit: contain;
      border-radius: 6px;
    }
  }
}
