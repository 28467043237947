.btn-wrapper {
  .btn {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 16px;
    align-items: center;
    background: #ffffff;
    border: 1px solid #d9d9d9;
    box-shadow: 0px 1px 2px rgba(16, 16, 16, 0.05);
    border-radius: 6px;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #101010;

    &:active {
      transform: scale(0.9);
      transition: transform 0.1s ease-in-out;
    }
    &:disabled {
      transform: unset;
      color: #9e9e9e;
    }
    &.white {
      margin-right: 24px;
    }
    &.black {
      background: #101010;
      color: #ffffff;
    }
  }
}