.wrapper-btn-not-active {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #f5f5f5;
  padding: 16px;
  margin-bottom: 16px;
  border-radius: 16px;
  .btn-not-active-text {
    font-family: "Inter";
    color: #101010;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px; /* 142.857% */
  }
  .btn-not-active,
  .btn-edit-listing {
    background: #fff;
    display: flex;
    padding: 10px 16px;
    justify-content: center;
    align-items: center;
    color: #101010;
    cursor: pointer;
    /* Text sm/Medium */
    font-family: "Inter";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 142.857% */

    border-radius: 6px;
    border: 1px solid #d9d9d9;
    background: #fff;
    box-shadow: 0px 1px 2px 0px rgba(16, 16, 16, 0.05);
  }

  .btn-edit-listing {
    svg {
      margin-right: 4px;
    }
  }
}

.wrapper-btn-not-inactive {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  background: #f5f5f5;
  padding: 16px;
  margin-bottom: 16px;
  border-radius: 16px;
  .btn-not-active-text {
    font-family: "Inter";
    color: #101010;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px; /* 142.857% */
  }
  .btn-not-active {
    background: #fff;
    display: flex;
    padding: 10px 16px;
    justify-content: center;
    align-items: center;
    color: #101010;
    cursor: pointer;
    /* Text sm/Medium */
    font-family: "Inter";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 142.857% */

    border-radius: 6px;
    border: 1px solid #d9d9d9;
    background: #fff;
    box-shadow: 0px 1px 2px 0px rgba(16, 16, 16, 0.05);
    margin-right: 8px;
  }
  .btn-not-active-delete {
    background: #fa0000;
    display: flex;
    padding: 10px 16px;
    justify-content: center;
    align-items: center;
    color: #ffffff;
    cursor: pointer;
    /* Text sm/Medium */
    font-family: "Inter";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 142.857% */

    border-radius: 6px;
    // border: 1px solid #d9d9d9;
    background: #fa0000;
    box-shadow: 0px 1px 2px 0px rgba(16, 16, 16, 0.05);
  }
}

.detail-listing-point {
  .wrapper-btn-options {
    position: relative;
  }
  .btn-options {
    display: flex;
    width: 40px;
    height: 40px;
    justify-content: center;
    align-items: center;
    position: relative;
    border-radius: 6px;
    border: 1px solid #d9d9d9;
    background: #fff;
    box-shadow: 0px 1px 2px 0px rgba(16, 16, 16, 0.05);
    cursor: pointer;
    svg {
      width: 14px;
      height: 14px;
    }

    .new-options {
      position: absolute;
      z-index: 1;
      right: -6px;
      top: -6px;
    }
  }

  .wrapper-edit-listing {
    position: absolute;
    top: 50px;
    right: 0;
    z-index: 2;
    border-radius: 6px;
    border: 1px solid #d9d9d9;
    background: #fff;
    box-shadow: 0px 1px 2px 0px rgba(16, 16, 16, 0.05);
    width: 235px;
    .btn-edit-listing {
      display: flex;
      padding: 10px 16px;
      align-items: center;
      cursor: pointer;
      width: 100%;
      position: relative;
      border-bottom: 1px solid #d9d9d9;

      &:first-child {
        border-radius: 6px 0px 0px 0px;
      }
      &:last-child {
        border-radius: 0px 0px 0px 6px;
        border-bottom: unset;
      }
      svg {
        width: 16px;
        height: 16px;
        margin-right: 8px;
      }
      color: #101010;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px; /* 142.857% */

      .new {
        position: absolute;
        z-index: 1;
        color: #fff;
        font-size: 8px;
        font-weight: 600;
        padding: 2px 4px;
        border-radius: 12px;
        background: #fa0000;
        position: absolute;
        right: 7px;
        line-height: unset;
      }
    }
  }
}

#modal-konfirmasi-tandai-listing {
  .modal-content {
    background: white;
    padding: 16px 24px 32px;
    width: 488px;
    border-radius: 6px;
    .close-icon-wrapper {
      display: flex;
      justify-content: end;
      margin-bottom: 32px;
      svg {
        width: 16px;
        height: 16px;
        cursor: pointer;
      }
    }

    .title {
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      color: #101010;
      margin-bottom: 24px;
      text-align: center;
    }

    .info {
      font-weight: 400;
      font-size: 12px;
      line-height: 18px;
      color: #676767;
      margin-bottom: 32px;
      text-align: center;

      span {
        color: #101010;
      }
    }

    .button-wrapper {
      display: flex;
      align-items: center;
      padding-top: 16px;
      border-top: 1px solid #ececec;
      justify-content: center;
      .ya {
        cursor: pointer;
        background: #ffffff;
        border: 1px solid #d9d9d9;
        box-shadow: 0px 1px 2px rgba(16, 16, 16, 0.05);
        border-radius: 6px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 10px 16px;
        margin-right: 24px;
        span {
          font-weight: 500;
          font-size: 14px;
          line-height: 20px;
          color: #101010;
        }
      }
      .batal {
        cursor: pointer;
        background: #101010;
        box-shadow: 0px 1px 2px rgba(16, 16, 16, 0.05);
        border-radius: 6px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 10px 16px;
        span {
          font-weight: 500;
          font-size: 14px;
          line-height: 20px;
          color: #ffffff;
        }
      }
    }
  }
}

.detail-listing-point-modal-loader {
  width: 100%;
  height: 100%;
  max-width: unset;
  background: transparent !important;
  display: flex;
  justify-content: center;
  align-items: center;
}
