.dashboard-leads-body-nav {
  padding: 34px 32px 32px 32px;
  .btn-nav-wrapper {
    display: flex;
    align-items: center;
    button {
      padding: 10px 22px;
      background: #ffffff;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      color: #101010;
      border: 1px solid #d9d9d9;
      cursor: pointer;
      
      &:first-child {
        border-radius: 6px 0px 0px 6px;
      }
      &:last-child {
        border-radius: 0px 6px 6px 0px;
      }

      &.selected {
        background: #101010;
        box-shadow: 0px 1px 2px rgba(16, 16, 16, 0.05);
        color: #ffffff;
      }
    }
  }
}
