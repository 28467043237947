.wrapper {
  width: calc(100vw - 193px);
  margin-left: 193px;
}

.header {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 32px;
  background: #ffffff;
  border-bottom: 0.5px solid #ececec;
  box-shadow: 0px 1px 3px rgba(16, 16, 16, 0.1), 0px 1px 2px rgba(16, 16, 16, 0.06);

  .title {
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 30px;
    line-height: 38px;
    color: #9e9e9e;
  }

  button {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px 16px;
    background: #ffffff;
    border: 1px solid #d9d9d9;
    box-shadow: 0px 1px 2px rgba(16, 16, 16, 0.05);
    border-radius: 6px;

    svg {
      margin-right: 8px;
      width: 14px;
      height: 14px;
    }

    span {
      font-family: "Inter";
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      color: #101010;
    }
  }
}

.body {
  padding: 55px 32px;
  min-height: calc(100vh - 107px - 109px);
  display: flex;
  justify-content: center;
  .content {
    border: 0.5px solid #ececec;
    box-shadow: 0px 1px 3px rgba(16, 16, 16, 0.1), 0px 1px 2px rgba(16, 16, 16, 0.06);
    border-radius: 6px;
    padding: 24px;
    width: 80%;

    .banner {
      width: 100%;
      height: 312.25px;
      margin-bottom: 48px;

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }

    .jenis-event {
      margin-bottom: 64px;
      padding-bottom: 64px;
      border-bottom: 1px solid #ececec;
      padding: 0 calc(120px - 24px);

      .title {
        font-family: "Inter";
        font-style: normal;
        font-weight: 500;
        font-size: 24px;
        line-height: 32px;
        color: #101010;
        margin-bottom: 8px;
      }
      .sub-title {
        font-family: "Inter";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #676767;
        margin-bottom: 32px;
      }

      .list-jenis-event {
        display: flex;
        align-items: center;
        margin-bottom: 32px;
        .item {
          display: flex;
          align-items: center;
          cursor: pointer;
          margin-right: 80px;

          svg {
            margin-right: 16px;

            &.uncheck{
              rect{
                fill: #ffffff;
              }
              path{
                display: none;
              }
            }
          }

          span {
            font-family: "Inter";
            font-style: normal;
            font-weight: 400;
            font-size: 20px;
            line-height: 30px;
            color: #101010;
          }

          &:last-child {
            margin-right: 0;
          }
        }
      }
    }

    .informasi-event {
      margin-bottom: 64px;
      padding-bottom: 64px;
      border-bottom: 1px solid #ececec;
      padding: 0 calc(120px - 24px);

      .informasi-event {
        box-sizing: border-box;
        padding: 16px;
        background: #fff4cc;
        border: 1px solid #fec601;
        border-radius: 6px;

        .title {
          font-family: "Inter";
          font-style: normal;
          font-weight: 500;
          font-size: 24px;
          line-height: 32px;
          color: #101010;
          margin-bottom: 4px;
        }
        .sub-title {
          font-family: "Inter";
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          color: #101010;
          margin-bottom: 8px;
        }

        ul {
          margin-left: 16px;
          li {
            margin-bottom: 4px;
            &:last-child {
              margin-bottom: 0;
            }
            span {
              font-family: "Inter";
              font-style: normal;
              font-weight: 400;
              font-size: 16px;
              line-height: 24px;
              color: #101010;
            }
          }
        }
      }
    }

    .pilih-marketing {
      padding: 0 calc(120px - 24px);

      .title {
        font-family: "Inter";
        font-style: normal;
        font-weight: 500;
        font-size: 24px;
        line-height: 32px;
        color: #101010;
        margin-bottom: 4px;
      }

      .sub-title {
        font-family: "Inter";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #676767;
        margin-bottom: 34px;
      }

      .marketing-terpilih {
        font-family: "Inter";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #676767;
        border-bottom: 1px solid #ececec;
        margin-bottom: 32px;
        padding-bottom: 32px;

        span {
          margin-left: 8px;
          font-family: "Inter";
          font-style: normal;
          font-weight: 600;
          font-size: 18px;
          line-height: 28px;
          color: #101010;
        }
      }

      .list-marketing {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-column-gap: 24px;

        .item {
          display: flex;
          align-items: center;
          margin-bottom: 40px;
          cursor: pointer;
          width: 100%;

          svg {
            width: 24px;
            height: 24px;
            margin-right: 12px;
          }
          span {
            font-family: "Inter";
            font-style: normal;
            font-weight: 400;
            font-size: 20px;
            line-height: 30px;
            color: #101010;
          }

          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
  }
}

.footer {
  width: 100%;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 32px;
  background: #ffffff;
  border-top: 0.5px solid #ececec;
  box-shadow: 0px -1px 3px rgba(16, 16, 16, 0.1), 0px -1px 2px rgba(16, 16, 16, 0.06);

  .btn {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px 16px;
    background: #101010;
    box-shadow: 0px 1px 2px rgba(16, 16, 16, 0.05);
    border-radius: 6px;

    span {
      font-family: "Inter";
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: #ffffff;
    }

    svg {
      margin-left: 12px;
      width: 14px;
      height: 14px;
    }
  }
}
