.wrapper {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  padding: 14px 10px;
  width: 370px;
  background: #ffffff;
  border: 1px solid #d9d9d9;
  border-radius: 6px;

  svg {
    width: 24px;
    height: 24px;
    margin-right: 8px;
  }

  input {
    width: 100%;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #101010;
    
    &::placeholder {
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: #9e9e9e;
    }
  }
}
