.wrapper {
  height: 100vh;
  width: calc(100vw - 193px);
  margin-left: 193px;
}

.header {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 32px;
  background: #ffffff;
  border-bottom: 0.5px solid #ececec;
  box-shadow:
    0px 1px 3px rgba(16, 16, 16, 0.1),
    0px 1px 2px rgba(16, 16, 16, 0.06);

  .title {
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 30px;
    line-height: 38px;
    color: #101010;
    margin-bottom: 8px;
  }

  .welcome {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #676767;
    margin-bottom: 40px;
  }

  .actions-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    .btns-wrapper {
      display: flex;
      align-items: center;
      .btn {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 10px 16px;
        border: 1px solid #d9d9d9;
        box-shadow: 0px 1px 2px rgba(16, 16, 16, 0.05);
        background: #ffffff;
        cursor: pointer;
        span {
          font-family: "Inter";
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 20px;
          color: #101010;
        }

        &:first-child {
          border-radius: 6px 0px 0px 6px;
        }
        &:last-child {
          border-radius: 0px 6px 6px 0px;
        }
      }

      .active {
        border: 1px solid #101010;
        background: #101010;
        box-shadow: 0px 1px 2px rgba(16, 16, 16, 0.05);
        span {
          color: #ffffff;
        }
      }
    }

    .btn-calendar {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 10px 16px;
      background: #ffffff;
      border: 1px solid #d9d9d9;
      box-shadow: 0px 1px 2px rgba(16, 16, 16, 0.05);
      border-radius: 6px;

      svg {
        width: 14px;
        height: 14px;
        margin-right: 8px;
      }
      span {
        font-family: "Inter";
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: #101010;
      }
    }
  }
}

.content {
  padding: 40px;
  height: calc(100vh - 217px - 124px);
}

.table {
  width: 100%;
  border: 0.5px solid #ececec;
  box-shadow:
    0px 1px 3px rgba(16, 16, 16, 0.1),
    0px 1px 2px rgba(16, 16, 16, 0.06);
  border-radius: 6px;
  overflow-y: hidden;
  scroll-behavior: smooth;
  .header-row {
    background: #f5f5f5;
    display: flex;
    justify-content: space-between;
    padding: 8px 16px;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    .header-cell {
      span {
        font-family: "Inter";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #9e9e9e;
      }
    }
  }

  .body-row {
    display: flex;
    justify-content: space-between;
    padding: 16px;
    background: #ffffff;
    border-bottom: 1px solid #ececec;

    .body-cell {
      span {
        font-family: "Inter";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #101010;
      }
      &.asal-leads {
        span {
          background: #ececec;
          border-radius: 80px;
          color: #101010;
          padding: 2px 12px;
        }
      }
      &:last-child {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
      &.disabled {
        button {
          span{
            color: "#ECECEC";
          }
        }
      }
    }

    &:last-child {
      border-bottom: unset;
      border-bottom-left-radius: 6px;
      border-bottom-right-radius: 6px;
    }
  }
}
