.wrapper {
  display: grid;
  grid-template-columns: 36.66% 63.34%;
  grid-column-gap: 8px;
  width: 100%;
}

.field {
  margin-bottom: 24px;
  .label {
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: #797979;
    margin-bottom: 4px;
  }

  .value {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #101010;
  }
}

.label-wrapper {
  p {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 4px 8px;
    width: 202px;
    height: 26px;
    background: #ececec;
    border-radius: 100px;

    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    color: #101010;
  }
}

.title {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #101010;
  width: 100%;
}

.list {
  width: 100%;
}

//mb-0
.mb-0 {
  margin-bottom: 0;
}
