@import "~styles/_variables.scss";

.c-modal {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
  z-index: $zIndex;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 40;
  .backdrop {
    z-index: 41;
    height: 100%;
    width: 100%;
    position: absolute;
  }
}
