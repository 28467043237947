#modal-success-v1 {
  .modal-content {
    width: 100%;
    max-width: 575px;
    background: #fff;
    padding: 20px 24px;
    border: 0.5px solid #ececec;
    box-shadow: 0px 1px 3px rgba(16, 16, 16, 0.1), 0px 1px 2px rgba(16, 16, 16, 0.06);
    border-radius: 6px;
    position: relative;

    .close-icon {
      width: 16px;
      height: 16px;
      position: absolute;
      top: 20px;
      right: 24px;
      cursor: pointer;
    }

    .modal-body {
      display: flex;
      flex-direction: column;
      align-items: center;

      .header-image {
        width: 360px;
        height: 360px;
        object-fit: contain;
        margin-bottom: 32px;
      }

      .title {
        width: 100%;
        padding: 0 24px;
        font-weight: $font-bolder;
        font-size: $fontXL;
        line-height: calc($ln-height-XXL - 6px);
        margin-bottom: 24px;
        color: $blackV1;
      }

      .info {
        width: 100%;
        padding: 0 24px;
        font-size: $fontS;
        line-height: $ln-height-S;
        margin-bottom: 32px;
        color: $greyV1;
        margin-bottom: 32px;
      }
    }

    .divider {
      border: 1px solid #ECECEC;
    }

    .modal-footer {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      padding: 16px;
    }
  }
}
