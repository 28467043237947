.laporan-form-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 24px 32px;
  border-bottom: 1px solid #ececec;

  p {
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 32px;
    color: #9e9e9e;
  }
  .btns-wrapper{
    display: flex;
    align-items: center;
    button {
      margin-left: 16px;
    }
  }
  .approval-btn{
    margin-right: 16px;
    margin-left: 0;
    width: auto;
    background: black;
    border: unset;
    box-shadow: unset;
    span{
      color: #fff;
    }
  }
  button {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 16px;
    border: 1px solid #d9d9d9;
    box-shadow: 0px 1px 2px rgba(16, 16, 16, 0.05);
    border-radius: 6px;
    background: #ffffff;
    cursor: pointer;
    svg {
      width: 14px;
      height: 14px;
      margin-right: 8px;
    }
  }
}
