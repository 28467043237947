// Background
.point-card-bg {
  background: linear-gradient(90deg, #bcaf8b 0%, #fff3d0 136.84%);
}

.jumlah-sharelisting {
  background: linear-gradient(90deg, rgba(236, 236, 236, 0.00) 0%, #ECECEC 100%);
}

.kenapa-hotlisting {
  background: linear-gradient(90deg, #EBE6DA 0%, rgba(235, 230, 218, 0.00) 100%);
}

// Flexbox
.box-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.box-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.box-end {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

// Icons (SVGS and IMAGES)
.icon-l {
  width: 24px;
  height: 24px;
  object-fit: contain;
}

//Buttons
.button-black {
  border: none;
  background: $blackV1;
  padding: 10px 16px;
  box-shadow: $bx-shadow-v1;
  border-radius: 6px;
  cursor: pointer;
  span {
    color: #fff;
    font-weight: $font-bold;
    font-size: $fontL;
    line-height: $ln-height-M;
  }
  &.disabled {
    background: $greyV3;
    cursor: default;
  }
}
.button-v2 {
  background: #fff;
  padding: 16px;
  box-shadow: 0px 1px 2px rgb(16 16 16 / 5%);
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  cursor: pointer;
  border: 1px solid #d9d9d9;
}

//Checkbox
.check-v1 {
  width: 16px;
  height: 16px;
}

.box-shadow-0 {
  box-shadow:
    0px 1px 2px 0px rgba(16, 16, 16, 0.06),
    0px 1px 3px 0px rgba(16, 16, 16, 0.1);
}

.box-shadow-1 {
  box-shadow:
    0px -1px 2px 0px rgba(16, 16, 16, 0.06),
    0px -1px 3px 0px rgba(16, 16, 16, 0.1);
}

.box-shadow-2 {
  box-shadow:
    0px 1px 3px rgba(16, 16, 16, 0.1),
    0px 1px 2px rgba(16, 16, 16, 0.06);
}

.hide-scrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
}
