@import "~styles/mixins.scss";

.wrapper {
  @include font-family;

  display: flex;
  align-items: center;
  padding: 8px 0;

  border-bottom: 1px solid #9e9e9e;
  &.disabled{
    background: #f5f5f5;
  }
  .percent {
    width: 32px;
    height: 32px;
    margin-right: 8px;
  }
  input {
    font-family: "Inter";
    font-style: normal;
    font-weight: 300;
    font-size: 36px;
    line-height: 44px;
    color: #101010;
    margin-right: 8px;
    width: 100%;
    &::placeholder {
      font-style: normal;
      font-weight: 300;
      font-size: 36px;
      line-height: 44px;
      color: #d9d9d9;
    }
    &:disabled{
      background: #f5f5f5;
    }
  }
  .x {
    width: 32px;
    height: 32px;
  }
}
