.map-btn {
  background-color: $blackV1;
  max-height: 40px;
  width: auto;
  white-space: nowrap;
  margin-left: 8px;
  .map-icon path {
    fill: white;
  }
  color: #fff;
}
