.custom-btn-with-icon {
  border: none;

  svg {
    width: 16px;
    height: 16px;
    margin-right: 8px;
    path {
      fill: $blackV1;
    }
  }
  color: $blackV1;
  font-weight: $font-bold;
  font-size: $fontS;
  line-height: $ln-height-S;

  &.disabled {
    background: $greyV4;
  }
}
