@import "../../../../styles/fonts";

#kmod-navtab {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 96px;
  margin-right: 40px;
  .btn-wrapper {
    display: flex;
    align-items: center;
    button {
      width: 210px;
      height: 40px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 8px 16px;
      background: #141414;
      cursor: pointer;
      span {
        font-family: "SF Pro Display";
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        line-height: 18px;
        text-align: center;
        color: #e9eef6;
      }
    }
    .left{
      border-top-left-radius: 32px;
      border-bottom-left-radius: 32px;
    }
    .right{
      border-top-right-radius: 32px;
      border-bottom-right-radius: 32px;
    }
    .active {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 8px 16px;
      width: 210px;
      height: 40px;
      background: #2356a6;
      span {
        font-family: "SF Pro Display";
        font-style: normal;
        font-weight: 600;
        font-size: 13px;
        line-height: 18px;
        color: #e9eef6;
      }
    }
  }
}
