.c-date-input-v1 {
  .label {
    font-weight: $font-bold;
    font-size: $fontXS;
    line-height: $ln-height-S;
    margin-bottom: 8px;
  }

  .textarea-wrapper {
    padding: 10px 16px;
    background: #fff;
    border-radius: 6px;
    box-shadow: $bx-shadow-v1;
    border: 1px solid #d9d9d9;

    input {
      width: 100%;
      height: 20px;
      cursor: pointer;
      color: $blackV1;
      font-size: $fontM;
      font-weight: $font-normal;
      font-family: $font-family;
      resize: none; 
      &::placeholder {
        color: $greyV2;
        font-size: $fontM;
        font-weight: $font-normal;
        font-family: $font-family;
      }
    }
    &.disabled {
      background: $greyV4;
      input {
        background: $greyV4;
      }
    }
  }
}
