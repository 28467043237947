#upload-listing {
  .process-container {
    display: flex;
    //Local usage
    .gsf-title {
      font-family: "Inter";
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      color: #676767;
      display: flex;
      align-items: center;
    }
    .mandatory-text,
    .additional-text,
    .optional-text {
      color: $redV1;
      font-size: $fontXS;
      line-height: $ln-height-XS;
      font-weight: $font-normal;
      margin-bottom: 8px;
    }
    .additional-text {
      color: $greyV1;
    }
    .optional-text {
      color: $greenV2;
    }

    .custom-label {
      white-space: nowrap;
      p {
        font-weight: $font-bold;
        font-size: $fontM;
        line-height: $ln-height-M;
      }
    }

    .update-listing-navbar {
      padding: 24px;
      border-right: 1px solid #ececec;
      height: calc(100vh - 134px);

      .listing-navbar-tab {
        display: flex;
        justify-content: space-between;
        padding: 8px 0;
        border-bottom: 1px solid #ececec;
        cursor: pointer;
        white-space: nowrap;
        a {
          font-family: $font-family;
          font-weight: $font-normal;
          font-size: $fontL;
          line-height: $ln-height-L;
          color: $greyV2;
        }
        .complete-icon {
          margin-left: 16px;
          width: 20px;
          height: 20px;
          object-fit: contain;
        }
      }
      .current {
        a {
          color: $blackV1;
        }
      }
    }
    .update-listing-forms-container {
      width: calc(100% - 250px);
      height: calc(100vh - 134px);
      overflow-y: auto;
      .form-container-outer {
        width: 100%;
        display: flex;
        background: #fcfcfd;
        flex-direction: column;
        align-items: center;
      }

      //Global form container
      .form-container {
        width: 100%;
        max-width: 770px;
      }

      //Identitas Listing Section && Spesifikasi property section
      .identitas-listing-container,
      .spesifikasi-property-container {
        // Marketing Associate
        .marketing-associate {
          padding: 24px 0;
          border-bottom: 1px solid #ececec;
          .assosiate-name {
            color: #676767;
            font-size: $fontM;
            font-weight: $font-normal;
            line-height: $ln-height-M;
            margin-bottom: 42px;
            span {
              font-weight: $font-bold;
              font-size: calc($fontL + 2px);
              line-height: $ln-height-M;
              color: $blackV1;
            }
          }
          .skor-listing {
            color: #676767;
            font-size: $fontM;
            font-weight: $font-normal;
            line-height: $ln-height-M;
            .score-card {
              background: linear-gradient(90deg, #bcaf8b 0%, #fff3d0 136.84%);
              border: 0.8px solid #b0a071;
              border-radius: 8px;
              padding: 4px 8px;

              .score-point {
                font-family: "SF Pro Display";
                font-style: normal;
                font-weight: 600;
                font-size: 17px;
                color: #232323;
              }
              .score-text {
                font-family: "SF Pro Display";
                font-style: normal;
                font-weight: 600;
                font-size: 14px;
                color: #232323;
                margin-left: 4px;
              }
            }
          }
        }

        // Identitas listing container
        .ilf-container {
          display: flex;
          padding: 56px 0;
          border-bottom: 1px solid #ececec;
          .g-section-text {
            width: 280px;
            white-space: wrap;
            margin-right: 64px;
            p {
              font-weight: $font-bold;
              font-size: $fontM;
              line-height: $ln-height-M;
            }
            .mandatory-text,
            .additional-text,
            .optional-text {
              color: $redV1;
              font-size: $fontXS;
              line-height: $ln-height-XS;
              font-weight: $font-normal;
              margin-bottom: 8px;
            }
            .additional-text {
              color: $greyV1;
            }
            .optional-text {
              color: $greenV2;
            }
          }
          .g-section-form {
            width: 100%;
            ._field {
              margin-bottom: 32px;
              &:last-of-type {
                margin-bottom: 0;
              }
            }
            .dble-input-label {
              font-weight: $font-bold;
              font-size: $fontXS;
              line-height: $ln-height-S;
              margin-bottom: 8px;
            }
            .double-input {
              display: flex;
              justify-content: space-between;
              align-items: center;
              margin-bottom: 32px;
              .double_field1 {
                margin-bottom: 0;
                margin-right: 24px;
                max-width: 122px;
              }
            }
            ._divider {
              border: 1px solid #ececec;
            }
            .hide-field {
              visibility: hidden;
            }
          }
        }

        // Listing
        .listing-container {
          .listing-form {
            .privasi-listing,
            .jenis-listing {
              margin-bottom: 32px;
              .title {
                color: $greenV1;
                font-size: $fontS;
                line-height: $ln-height-S;
                margin-bottom: 8px;
              }

              // Button wrapper
              .listing-btn-wrap {
                display: flex;
                .listing-button {
                  &.mr-16 {
                    margin-right: 16px;
                  }
                  &.selected {
                    background: #fff;
                    border: 1px solid #ececec;
                    background: $blackV1;
                    border: none;
                    svg path {
                      fill: $yellowV1;
                    }

                    span {
                      color: $yellowV1;
                    }
                  }
                }
              }
            }

            .jenis-listing {
              margin-bottom: 0;
            }
          }
        }
      }
      //Identitas Listing Button Wrapper
      .identitas-listing-btn-wrapper {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        width: 100%;
        border-top: 0.5px solid $greyV4;
        height: 134px;
        .save-identitas-listing {
          width: auto;
          margin-right: 32px;
          flex-direction: row-reverse;
          background: $blackV1;
          padding: 10px 16px;
          span {
            color: #fff;
          }
          svg {
            margin-right: 0;
            margin-left: 10px;
            path {
              fill: #fff;
            }
          }
        }
        .disabled {
          background: $greyV4;
          span {
            color: $greyV1;
          }
          svg {
            margin-right: 0;
            margin-left: 10px;
            path {
              fill: $greyV1;
            }
          }
        }
      }

      //Upload Gambar section
      .skor-listing-wrapper {
        width: 100%;
        display: flex;
        justify-content: center;
        .skor-listing-box {
          width: calc(100% - 340px);
          padding-top: 52px;
          padding-bottom: 24px;
          border-bottom: 1px solid #ececec;
          margin-bottom: 84px;
          .skor-listing {
            color: #676767;
            font-size: $fontM;
            font-weight: $font-normal;
            line-height: $ln-height-M;
            .score-card {
              background: linear-gradient(90deg, #bcaf8b 0%, #fff3d0 136.84%);
              border: 0.8px solid #b0a071;
              border-radius: 8px;
              padding: 4px 8px;
              .score-point {
                font-family: "SF Pro Display";
                font-style: normal;
                font-weight: 600;
                font-size: 17px;
                color: #232323;
              }
              .score-text {
                font-family: "SF Pro Display";
                font-style: normal;
                font-weight: 600;
                font-size: 14px;
                color: #232323;
                margin-left: 4px;
              }
            }
          }
        }
      }
      .upload-gambar-listing-wrapper {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding-bottom: 48px;
        margin-bottom: 48px;
        border-bottom: 1px solid #ececec;
        .no-image {
          width: 100%;
          max-width: 770px;
          display: flex;
          flex-direction: column;
          align-items: center;

          .no-image-wrapper {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
          }

          .upload-image {
            width: 60px;
            height: 60px;
            object-fit: contain;
            margin-bottom: 24px;
            path {
              stroke-width: 1;
            }
          }

          .drag-drop-text {
            font-weight: $font-bolder;
            font-size: $fontXL;
            line-height: calc($ln-height-XXL - 4px);
            text-align: center;
            margin-bottom: 8px;
          }
          .img-requirement-text {
            font-weight: $font-normal;
            font-size: $fontS;
            line-height: $ln-height-S;
            margin-bottom: 32px;
            color: $greyV1;
          }

          .choose-file-btn-wrapper {
            display: flex;
            align-items: center;
            justify-content: center;
            .atau {
              margin-right: 16px;
              font-weight: $font-normal;
              font-size: $fontS;
              line-height: $ln-height-S;
            }
            .choose-file-btn {
              background-color: #fff;
              border: 1px solid $greyV3;
              span {
                color: $blackV1;
              }
            }
          }

          .image-info-point {
            background: #ececec;
            border-radius: 4px;
            padding: 8px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 24px;
            span {
              font-family: "Inter";
              font-style: normal;
              font-weight: 500;
              font-size: 12px;
              color: #101010;
            }
          }
        }

        .have-image {
          .images-wrapper {
            margin-top: 48px;
            display: grid;
            grid-template-columns: repeat(5, 1fr);
            grid-gap: 24px;
          }
        }

        .loading-wrapper {
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-top: 24px;
          // visibility: hidden;
        }
        .show-loading {
          visibility: visible;
        }
      }
    }
  }

  .upload-files-at-upload-listing {
    min-height: calc(100vh - 135px - 134px);
    width: calc(100vw - 181px - 48px);
  }
}
