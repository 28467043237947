.wrapper {
  .row {
    width: 100%;
    display: grid;
    grid-template-columns: 2.18% 4.03% 16.78% 20.95% 12.58% 8.57% 3.36% 4.03%;
    grid-column-gap: 40px;
    align-items: center;
    padding: 32px 0;
    border-top: 1px solid #f5f5f5;
    .col {
      width: 100%;
    }
    .double {
      display: flex;
      width: 100%;
      flex-direction: column;
      justify-content: flex-start;
      .first {
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: #101010;
      }
      .last {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #676767;
      }
    }
    .image {
      width: 100%;
      height: 100%;
      img {
        width: 100%;
        max-width: 48px;
        height: 48px;
        object-fit: cover;
        border: 1px solid #ececec;
        filter: drop-shadow(0px 1px 2px rgba(16, 16, 16, 0.06));
        border-radius: 48px;
      }
    }

    .info {
      .info-wrapper {
        width: 40px;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #101010;
        box-shadow: 0px 1px 2px rgba(16, 16, 16, 0.06);
        border-radius: 6px;

        svg {
          width: 24px;
          height: 24px;
          cursor: pointer;
        }
      }
    }

    .active {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .active-text {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #101010;
        white-space: nowrap;
      }
    }
  }

  .centered{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 40px 0;
  }
}
