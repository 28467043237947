.date {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #9e9e9e;
  padding: 8px 0;
  svg {
    width: 32px;
    height: 32px;
    margin-right: 8px;
  }
  input {
    width: 100%;
    font-style: normal;
    font-weight: 300;
    font-size: 36px;
    line-height: 44px;
    color: #101010;
    &::-webkit-calendar-picker-indicator {
      display: none;
    }
    &::placeholder {
      font-style: normal;
      font-weight: 300;
      font-size: 36px;
      line-height: 44px;
      color: #d9d9d9;
    }
    &:disabled{
      background: #d9d9d9;
    }
  }
}
