.properti-detail-kontak-owner {
  padding-bottom: 40px;
  .kontak-owner-header {
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    color: #101010;
    margin-bottom: 24px;
  }
  .kontak-owner-contact {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #494949;
  }
}
