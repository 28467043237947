.wrapper {
  width: calc(100vw - 193px);
}

.header {
  padding: 32px;
  border-bottom: 0.5px solid #ececec;
  box-shadow:
    0px 1px 3px rgba(16, 16, 16, 0.1),
    0px 1px 2px rgba(16, 16, 16, 0.06);
  background: white;
  width: calc(100vw - 193px);

  .title {
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 30px;
    color: #101010;
    margin-bottom: 8px;
  }
  .profile {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    color: #676767;
  }

  .btn-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.invoice-number {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  color: #232323;
  span {
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    color: #a78d4d;
    margin-left: 8px;
  }
}

.content {
  display: flex;
  justify-content: center;
  margin-top: 265px;
}

.row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.mb-4 {
  margin-bottom: 4px;
}

.mb-8 {
  margin-bottom: 8px;
}

.mb-40 {
  margin-bottom: 40px;
}

.grey-small {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #9e9e9e;
}

.grey-regular {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #676767;
}

.black-small {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #101010;
}

.black-regular {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #101010;
}

.black-regular-bold {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  color: #101010;
}

.black-large {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  color: #101010;
}

.black-large-bold {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  color: #101010;
}

.bold {
  font-weight: 600;
}

.red-text {
  color: #af0000;
}

.detail-body {
  width: 100%;
  max-width: 624px;

  .invoice-status-wrapper {
    border-bottom: 1px solid #ececec;
    align-items: center;
  }
  .status {
    margin-bottom: 40px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    color: #af0000;

    &.menunggu {
      color: #af0000;
    }
    &.lunas {
      color: #00652b;
    }
    &.diputihkan {
      color: #2356a6;
    }
  }

  .info-kantor {
    align-items: flex-start;
    margin-bottom: 40px;
    padding: 16px 0;
    display: block;
    .kantor {
      margin-bottom: 24px;
    }
  }

  .detail-biaya {
    padding: 16px 0;
    border-top: 1px solid #f5f5f5;
    border-bottom: 1px solid #f5f5f5;
  }

  .biaya-normal {
    padding: 16px 0;
  }

  .biaya-double {
    padding: 16px 0;
  }

  .pph-23 {
    padding: 16px 0;
  }

  .total {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px;
    background: #f5f5f5;
  }
}

.note-pph23 {
  padding: 16px;
  background: #fff4cc;
  margin-top: 48px;

  .top-note {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
    svg {
      width: 16px;
      height: 16px;
      margin-right: 8px;
    }
    p {
      color: #232323;
      font-size: 15px;
      font-style: normal;
      font-weight: 600;
      line-height: 20px; /* 133.333% */
      letter-spacing: -0.23px;
    }
  }

  .note {
    color: #232323;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    span {
      font-weight: 600;
    }
  }
}
