.wrapper {
  display: flex;
  align-items: start;
  .icon {
    width: 32px;
    height: 32px;
    margin-right: 8px;
  }
  .text {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: #494949;
  }
}
