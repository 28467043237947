.lt-card {
  @include box-v1;
  padding: 11px 16px;
  width: 100%;
  max-width: 255px;
  height: 95px;
  .lt-card-price {
    margin-bottom: 10px;
    p {
      font-style: normal;
      font-weight: 600;
      font-size: 24px;
      line-height: 32px;
      color: #c1af82;
      span {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #9e9e9e;
      }
    }
  }
  .lt-card-info {
    @include flex-between;
    .title {
      font-weight: 400;
      font-size: 12px;
      line-height: 18px;
      color: #ffffff;
    }
    .unit {
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      color: #9e9e9e;
      span {
        color: #ffffff;
      }
    }
  }

  &.white {
    background: #fff;
    border: 1px solid #d9d9d9;
    box-shadow: 0px 1px 2px rgba(16, 16, 16, 0.05);
    border-radius: 6px;
    .lt-card-price {
      p {
        color: #101010;
      }
    }
    .lt-card-info {
      @include flex-between;
      .title {
        color: #101010;
      }
      .unit {
        color: #9e9e9e;
        span {
          color: #101010;
        }
      }
    }
  }
}
