.wrapper {
  padding: 8px 0;
  border-bottom: 1px solid #9e9e9e;
  display: flex;
  align-items: center;
  .icon {
    width: 32px;
    height: 32px;
    margin-right: 8px;
  }
  input {
    font-style: normal;
    font-weight: 300;
    font-size: 36px;
    line-height: 44px;
    color: #101010;
    width: calc(100% - 40px);
    &::placeholder {
      font-style: normal;
      font-weight: 300;
      font-size: 36px;
      line-height: 44px;
      color: #d9d9d9;
    }

    &:disabled {
      background: #d9d9d9;
    }
  }

  &.position-right{
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
  }
}
