.lt-footer-wrapper {
  @include flex-col-end;
  padding: 32px;
  border-top: 0.5px solid #ececec;
  box-shadow: 0px -1px 3px rgba(16, 16, 16, 0.1), 0px -1px 2px rgba(16, 16, 16, 0.06);
  background: #fff;
  .goto-wrapper {
    display: flex;
    align-items: center;
    .goto-text {
      @include text-14($color: #676767);
    }
    .goto-btn {
      width: auto;
      flex-direction: row-reverse;
      background: #ececec;
      padding: 15px;
      border: 1px solid #d9d9d9;
      border-radius: 8px;
      svg {
        margin-left: 24px;
        margin-right: 0;
        @include svg-v1;
      }
      @include btn-border;
    }
  }

  .pagination-wrapper {
    display: flex;
    align-items: center;
    .prev-btn {
      @include svg-v1;
      span {
        @include text-14($color: #9e9e9e);
      }
      @include btn-border;
    }
    .pagination-text {
      @include text-14($color: #9e9e9e);
      span {
        color: $blackV1;
      }
    }
    .next-btn {
      @include svg-v1;
      flex-direction: row-reverse;
      span {
        @include text-14($color: #9e9e9e);
      }
      svg {
        margin-left: 13px;
        margin-right: 0;
        @include svg-v1;
      }
      @include btn-border;
    }
  }
}
