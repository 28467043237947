.wrapper {
  display: flex;
  border: 1px solid #ececec;
  box-shadow: 0px 1px 2px rgba(16, 16, 16, 0.06);
  border-radius: 6px;
  width: 100%;
  cursor: pointer;
  .image {
    width: 37.22%;
    height: 100%;

    img {
      width: 100%;
      height: 100%;
      border-top-left-radius: 6px;
      border-bottom-left-radius: 6px;
      object-fit: cover;
    }
  }
  .content {
    width: 62.78%;
    padding: 8px 16px;

    .price {
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      color: #9a6e00;
      margin-bottom: 16px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 100%;
    }
    .title {
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: #101010;
      margin-bottom: 6px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .address {
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 18px;
      color: #676767;
      margin-bottom: 18px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .label-wrapper {
      display: flex;
      align-items: center;
      p {
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 18px;
        color: #ffffff;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 2px 16px;
        border-radius: 24px;
      }
      .jual {
        background: #0d814a;
      }
      .sewa {
        background: #fec601;
        color: #101010;
      }
      .id {
        margin-left: 16px;
        border: 1px solid #9e9e9e;
        background: #ffffff;
        color: #101010;
      }
      .hash {
        color: #9e9e9e;
      }
    }
  }
}
