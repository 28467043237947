#key-metric-office-dashboard {
  width: calc(100vw - 193px);
  margin-left: 193px;
  height: 100vh;
  background: #090909;
  overflow-y: scroll; /* Add the ability to scroll the y axis */
  /* Hide the scrollbar for Internet Explorer, Edge and Firefox */
  -ms-overflow-style: none; /* Internet Explorer and Edge */
  scrollbar-width: none; /* Firefox */
  /* Hide the scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    display: none;
  }
}
