.new-approval-page {
  padding: 40px 24px;
}
.divider {
  height: 1px;
  border-bottom: 1px solid #ececec;
  margin: 32px 0;
}
.field-content {
  .title {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: #9e9e9e;
    margin-bottom: 4px;
  }
  .konten {
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    color: #101010;
  }

  &.margin-right-48 {
    margin-right: 48px;
  }
}

.wrapper-metode {
  display: inline-block;
  .metode-transaksi {
    box-sizing: border-box;
    display: flex;
    align-items: center;
    padding: 8px 0;
    background: #fcfcfc;
    border-width: 0.5px 0.5px 0px 0.5px;
    border-style: solid;
    border-color: #ececec;
    border-radius: 6px 6px 0px 0px;

    .section {
      border-left: 1px dashed #d9d9d9;

      .title {
        font-family: "Inter";
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        color: #9e9e9e;
        margin-bottom: 8px;
      }
      .konten {
        font-family: "Inter";
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: #101010;
      }

      &:first-child {
        border-left: none;
        margin-left: 32px;
      }
      &.middle {
        padding: 0 32px;
        margin-left: 32px;
      }
      &:last-child {
        padding: 0 32px;
        margin-left: 32px;
      }
    }
  }
}

.wrapper-jumlah-marketing {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 40px 24px;
  background: #fffdf8;
  border: 0.5px solid #c1af82;
  border-radius: 6px;
  margin-bottom: 40px;

  .section {
    margin-right: 64px;
    &:last-child {
      margin-right: 0;
    }

    .title {
      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 18px;
      color: #9e9e9e;
      margin-bottom: 8px;
    }
    .konten {
      font-family: "Inter";
      font-style: normal;
      font-weight: 600;
      font-size: 24px;
      line-height: 32px;
      color: #101010;
      span {
        font-family: "Inter";
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 30px;
        color: #9e9e9e;
      }
    }
  }
}

.wrapper-marketing-and-commision {
  border: 1px solid #ececec;
  border-radius: 6px;
  margin-bottom: 40px;
  .wrapper-marketing {
    padding: 40px 141px;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #ececec;
    .marketing {
      width: 416px;
      height: 156px;

      &:first-child {
        margin-right: 208px;
      }

      .label {
        padding: 2px 8px;
        background: #0d814a;
        margin-bottom: 4px;

        font-family: "Inter";
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 18px;
        color: #ffffff;
      }

      .title {
        font-family: "Inter";
        font-style: normal;
        font-weight: 500;
        font-size: 30px;
        line-height: 38px;
        color: #d9d9d9;
        margin-bottom: 46px;
      }

      .name-and-commision {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .section {
          .title {
            font-family: "Inter";
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 18px;
            color: #9e9e9e;
            margin-bottom: 4px;
          }

          .konten {
            font-family: "Inter";
            font-style: normal;
            font-weight: 500;
            font-size: 20px;
            line-height: 30px;
            color: #101010;
          }
        }
      }
    }

    .marketing-pembeli {
      .label {
        background: #fec601;
        color: #101010;
      }
    }

    .marketing-non-century {
      width: 416px;

      &:first-child {
        margin-right: 208px;
      }

      .label {
        padding: 2px 8px;
        background: #0d814a;
        margin-bottom: 4px;

        font-family: "Inter";
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 18px;
        color: #ffffff;
      }

      .title {
        font-family: "Inter";
        font-style: normal;
        font-weight: 500;
        font-size: 30px;
        line-height: 38px;
        color: #d9d9d9;
        margin-bottom: 46px;
      }

      .name-and-commision {
        .section {
          &:first-child {
            margin-bottom: 32px;
            padding-bottom: 32px;
            border-bottom: 1px solid #ececec;
          }
          .title {
            font-family: "Inter";
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 18px;
            color: #9e9e9e;
            margin-bottom: 4px;
          }

          .konten {
            font-family: "Inter";
            font-style: normal;
            font-weight: 500;
            font-size: 20px;
            line-height: 30px;
            color: #101010;
          }
        }
      }
    }
    .marketing-pembeli-non-century {
      .label {
        background: #fec601;
        color: #101010;
      }
    }
  }
  .wrapper-co-marketing {
    padding: 40px 141px;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #ececec;
    background: #fcfcfc;
    .marketing {
      width: 416px;
      height: 156px;

      &:first-child {
        margin-right: 208px;
      }

      .label {
        margin-bottom: 4px;

        font-family: "Inter";
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 18px;
        color: #101010;
      }

      .title {
        font-family: "Inter";
        font-style: normal;
        font-weight: 500;
        font-size: 30px;
        line-height: 38px;
        color: #d9d9d9;
        margin-bottom: 46px;
      }

      .name-and-commision {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .section {
          .title {
            font-family: "Inter";
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 18px;
            color: #9e9e9e;
            margin-bottom: 4px;
          }

          .konten {
            font-family: "Inter";
            font-style: normal;
            font-weight: 500;
            font-size: 20px;
            line-height: 30px;
            color: #101010;
          }
        }
      }
    }
  }

  .wrapper-refferal {
    padding: 40px 141px;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #ececec;
    .non-marketing {
      width: 416px;
      height: 156px;

      &:first-child {
        margin-right: 208px;
      }

      .label {
        margin-bottom: 4px;

        font-family: "Inter";
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 18px;
        color: #101010;
      }

      .title {
        font-family: "Inter";
        font-style: normal;
        font-weight: 500;
        font-size: 30px;
        line-height: 38px;
        color: #d9d9d9;
        margin-bottom: 46px;
      }

      .name-and-commision {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .section {
          .title {
            font-family: "Inter";
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 18px;
            color: #9e9e9e;
            margin-bottom: 4px;
          }

          .konten {
            font-family: "Inter";
            font-style: normal;
            font-weight: 500;
            font-size: 20px;
            line-height: 30px;
            color: #101010;
          }
        }
      }
    }
  }
}

.box-informasi-penjual-pembeli {
  border: 1px solid #ececec;
  border-radius: 6px;
  margin-bottom: 40px;

  .wrapper-title {
    padding: 40px 141px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #ececec;

    .title {
      font-family: "Inter";
      font-style: normal;
      font-weight: 600;
      font-size: 30px;
      line-height: 38px;
      color: #676767;
    }
  }

  .box-data-penjual-pembeli {
    width: 100%;
    display: flex;
    padding: 40px 141px;

    .section {
      width: 100%;
      &:first-child {
        margin-right: 208px;
      }

      .section-title {
        font-family: "Inter";
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 18px;
        color: #101010;
        margin-bottom: 4px;

        span {
          background: #101010;
          padding: 2px 8px;
          font-family: "Inter";
          font-style: normal;
          font-weight: 600;
          font-size: 12px;
          line-height: 18px;
          color: #ffffff;
          margin-left: 8px;
        }
      }

      .section-header {
        font-family: "Inter";
        font-style: normal;
        font-weight: 500;
        font-size: 30px;
        line-height: 38px;
        color: #d9d9d9;
        margin-bottom: 48px;
      }
      .section-detail {
        display: flex;
        align-items: center;
      }
    }
  }
}

.wrapper-lihat-detail-property {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  background: #ffffff;
  border: 1px solid #2356a6;
  border-radius: 6px;
  padding: 16px 40px;
  margin-bottom: 8px;

  .title {
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    color: #101010;
    margin-bottom: 8px;
  }
  .info {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: #9e9e9e;
  }

  .wrapper-arrow-icon {
    width: 32px;
    height: 32px;
  }
}

.wrapper-detail-property {
  display: none;
  border: 1px solid #ececec;
  border-radius: 6px;
  &.show {
    display: block;
  }

  .box-between {
    display: flex;
    justify-content: space-between;
    padding-bottom: 1px solid #ececec;
    padding: 40px 141px;
  }
  .section {
    width: 416px;
  }
  .box-detail-property {
    justify-content: flex-start;
    .image {
      width: 200px;
      height: 200px;
      border: 1px solid #ececec;
      filter: drop-shadow(0px 1px 2px rgba(16, 16, 16, 0.06));
      border-radius: 6px;
      object-fit: cover;
      margin-right: 48px;
    }

    .detail-property {
      .labels {
        margin-bottom: 4px;
        span {
          padding: 2px 8px;
          background: #ececec;

          font-family: "Inter";
          font-style: normal;
          font-weight: 600;
          font-size: 12px;
          line-height: 18px;
          color: #101010;
          margin-right: 16px;
        }
        .penjual {
          background: #0d814a;
          color: #ffffff;
        }
        .pembeli {
          background: #fec601;
          color: #101010;
        }
      }
      .title {
        font-family: "Inter";
        font-style: normal;
        font-weight: 500;
        font-size: 30px;
        line-height: 38px;
        color: #101010;
        margin-bottom: 46px;
      }
      .title-address {
        font-family: "Inter";
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        color: #9e9e9e;
        margin-bottom: 4px;
      }
      .address {
        font-family: "Inter";
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 30px;
        color: #101010;
      }
    }
  }

  .box-spec-property {
    background: #fcfcfc;
    .section-header {
      font-family: "Inter";
      font-style: normal;
      font-weight: 500;
      font-size: 30px;
      line-height: 38px;
      color: #9e9e9e;
      margin-bottom: 48px;
    }

    .section-detail {
      display: flex;
      align-items: center;
    }
  }
}

.btn-wrapper {
  border-top: 1px solid #ececec;
  padding: 16px;
  padding-bottom: 32px;
  display: flex;
  width: 100%;
  justify-content: flex-end;
  .btn {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 16px;
    align-items: center;
    background: #ffffff;
    border: 1px solid #d9d9d9;
    box-shadow: 0px 1px 2px rgba(16, 16, 16, 0.05);
    border-radius: 6px;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #101010;

    &:active {
      transform: scale(0.9);
      transition: transform 0.1s ease-in-out;
    }
    &:disabled {
      transform: unset;
      color: #9e9e9e;
    }
    &.white {
      margin-right: 24px;
    }
    &.black {
      background: #101010;
      color: #ffffff;
    }
  }
}
