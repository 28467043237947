.custom-btn {
  border: none;
  background: $blackV1;
  padding: 10px 16px;
  box-shadow: $bx-shadow-v1;
  border-radius: 6px;
  cursor: pointer;
  span{
    color: #fff;
    font-weight: $font-bold;
    font-size: $fontL;
    line-height: $ln-height-M;
  }

  &.disabled {
    background: $greyV3;
    cursor: default;
  }
  &:active{
    background: #fff;
    border: 1px solid $greyV1;

  }
}