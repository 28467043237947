.order {
  padding: 2px 8px;
  border-radius: 4px;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  margin-right: 19px;
  color: #101010;
  
  &.with-bg {
    background: #101010;
    color: #ffffff;
  }
}
