.listing-detail-address {
  padding-bottom: 40px;
  margin-bottom: 40px;
  border-bottom: 1px solid #ECECEC;
  .detail-address-card {
    display: flex;
    .address-card-icon {
      width: 24px;
      height: 24px;
      margin-right: 8px;
    }
    .address-content-wrapper {
      .address-header {
        font-family: "Inter";
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: #101010;
        margin-bottom: 8px;
        span{
          color: #AF0000;
        }
      }
      .address-content {
        font-family: "Inter";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #494949;
      }
    }
  }
}
