.table {
  width: 100%;
  border: 0.5px solid #ececec;
  box-shadow: 0px 1px 3px rgba(16, 16, 16, 0.1), 0px 1px 2px rgba(16, 16, 16, 0.06);
  border-radius: 6px;
  border: 1px solid #d9d9d9;
}

.table-head {
  .content {
    background: #f5f5f5;
    padding: 8px 16px;
    span {
      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      color: #9e9e9e;
      display: block;
      text-align: left;
    }
  }
}

.table-body {
  .content {
    padding: 16px;
    background: #ffffff;
    border-bottom: 1px solid #d9d9d9;
    span {
      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      color: #101010;
    }

    .invoice_number {
      cursor: pointer;
      span {
        color: #2356a6;
        text-decoration: underline;
      }
    }

    .jatuh-tempo {
      display: flex;
      align-items: center;
      .label {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 4px 8px;
        background: #af0000;
        border-radius: 24px;
        margin-right: 24px;
        font-family: "Inter";
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        color: #ffffff;
      }
      span {
        color: #af0000;
      }
    }
  }
}

.table-foot {
  tr td {
    padding: 16px;
    background: #f5f5f5;
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    color: #232323;
  }
}
