.informasi-yang-ditampilkan {
  display: flex;
  align-items: center;
  .info-label {
    display: flex;
    align-items: center;
    margin-right: 16px !important;

    font-family: "SF Pro Display";
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 18px;
    color: #e9eef6;

    svg {
      width: 16px;
      height: 16px;
      margin-right: 8px;
    }
  }

  .input-number {
    background: #141414;
    border: 1px solid #1a1a1a;
    border-radius: 32px;
    height: 40px;
    box-sizing: border-box;
    padding: 8px 16px;
    display: inline-block;
    margin-right: 8px;
    width: 54px;
    input {
      background-color: #141414;
      border: none;
      font-family: "SF Pro Display";
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
      line-height: 18px;
      color: #a7a7a7;
      width: 100%;
    }
  }

  .dropdown-wrapper {
    box-sizing: border-box;
    display: flex;
    align-items: center;
    padding: 8px 16px;
    height: 40px;
    background: #141414;
    border: 1px solid #1a1a1a;
    border-radius: 32px;
    position: relative;
    width: 127px;
    white-space: nowrap;

    cursor: pointer;
    .selected-value {
      font-family: "SF Pro Display";
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
      line-height: 18px;
      color: #a7a7a7;
      display: flex;
      align-items: center;
      svg {
        width: 16px;
        height: 16px;
        margin-left: 16px;
      }
    }
    .custom-dropdown {
      position: absolute;
      left: 0;
      top: 40px;
      width: 127px;
      background: #141414;
      display: flex;
      flex-direction: column;
      border-radius: 32px;

      button {
        background: #141414;
        border: none;
        cursor: pointer;
        font-family: "SF Pro Display";
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        line-height: 18px;
        color: #a7a7a7;
        text-align: left;
        padding: 8px 16px;

        &:hover {
          background: #a7a7a7;
          color: #141414;
        }
      }
    }
  }
}
