.lt-body-wrapper {
  min-height: calc(100vh - 278px - 117px);

  .lt-body-nodata {
    @include flex-col-center;
    width: 100%;
    min-height: calc(100vh - 395px);
    .no-transaction {
      width: 347.64px;
      height: 254.3px;
    }
    .nots-text-1 {
      @include text-24;
    }
    .nots-text-2 {
      @include text-16($color: #676767);
    }
  }

  .lt-table-container {
    padding: 55px 32px;

    .approval-wrapper {
      width: auto;
      display: inline-block;
      margin-bottom: 55px;
      padding: 0;
      border: 1px solid #cecece;
      border-radius: 6px;

      .approval-btn {
        padding: 10px 16px;
        border-right: 1px solid #cecece;
        cursor: pointer;
        border-radius: 6px;
        display: flex;
        align-items: center;

        svg{
          margin-right: 8px;
        }
        span {
          color: #9e9e9e;
          font-weight: 500;
          font-size: 14px;
          line-height: 20px;
        }
      }

      .batalkan-pilihan.selected {
        span {
          color: $blackV1;
        }
      }
      .pilih-semua.selected {
        background: $blackV1;
        span {
          color: #fff;
        }
      }
      .setujui-yang-dipilih.selected {
        background: $blackV1;
        span {
          color: #fff;
        }
      }
    }
  }

  #confirmation-modal-v1 {
    &.modal-konfirmasi-approval {
      .modal-content {
        .modal-body {
          .title {
            font-family: "Inter";
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 24px;
            color: #101010;
          }
          .info {
            font-family: "Inter";
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 18px;
            color: #676767;
          }
        }
        .modal-footer {
          .custom-btn {
            padding: 10px 16px;
            span {
              font-family: "Inter";
              font-style: normal;
              font-weight: 500;
              font-size: 14px;
              line-height: 20px;
              color: #101010;
            }
          }
          .decline-btn {
            span {
              color: #ffffff;
            }
          }
        }
      }
    }
  }
}
