.lt-table-wrapper {
  padding: 32px;
  @include box-v2;
  table {
    width: 100%;
    
    thead {
      tr {
        td {
          white-space: nowrap;
          @include text-12($color: #9e9e9e);
        }
        &.checkbox {
          visibility: visible;
          &.hidden {
            visibility: hidden;
          }
        }
      }
    }

    tbody {
      tr {
        td {
          padding: 24px 0;
          border-bottom: 1px solid #d9d9d9;
          @include text-14($color: #101010);

          .type {
            @include text-12;
            padding: 2px 16px;
            background: #ececec;
            padding: 2px 16px;
            border-radius: 80px;
          }

          .info-icon {
            width: 34px;
            height: 34px;
            background: #101010;
            padding: 0;
            cursor: pointer;
            svg {
              @include svg-xs;
              margin: 0;
              height: 14px;
              width: 14px;
              path:last-child {
                fill: #fff;
              }
            }
          }
          .triple-dot {
            position: relative;
            display: inline-block;
            button {
              cursor: pointer;
            }
            .edit-or-approve {
              position: absolute;
              border: 1px solid #d9d9d9;
              box-shadow: 0px 1px 2px rgba(16, 16, 16, 0.05);
              border-radius: 6px;
              right: 0;
              background: #fff;
              z-index: 2;
              button {
                white-space: nowrap;
                cursor: pointer;
                padding: 10px 24px;
                width: 100%;
                text-align: left;
                display: flex;
                align-items: center;
                border-bottom: 1px solid #d9d9d9;

                svg {
                  margin-right: 8px;
                }
                &:first-child {
                  border-top-left-radius: 6px;
                  border-top-right-radius: 6px;
                }
                &:last-child {
                  border-bottom-left-radius: 6px;
                  border-bottom-right-radius: 6px;
                  border-bottom: none;
                }
              }

              .btn-remove {
                color: #af0000;
              }
            }
          }
          &.checkbox {
            visibility: visible;
            &.hidden {
              visibility: hidden;
            }
          }
          &.tanggal {
            color: #676767;
          }
          &.agents {
            width: 15%;
            .agents-name {
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              span {
                padding: 2px 0;
                @include text-14($color: #101010);
                margin-bottom: 8px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                width: 156px;
                &:last-child {
                  margin-bottom: 0;
                }
              }
            }
          }
          &.waiting {
            color: #af0000;
            width: 140px;
          }
          &.approved {
            color: #00652b;
          }
          &.komisi {
            font-family: "SF Pro Display SemiBold";
            color: #101010;
            font-size: 14px;
          }
          &.komisi-belum-terima {
            span {
              color: #af0000;
              text-align: right;
              font-size: 14px;
              font-family: "Inter";
              font-weight: 600;
              line-height: 20px;
            }
          }
          &.alamatProperti {
            color: #676767;
            text-align: left;
            font-size: 14px;
            font-family: "Inter";
            font-weight: 400;
            line-height: 20px;
            width: 128px;
          }
          &.tambah-info-baru-komisi {
            button {
              border-radius: 6px;
              border: 1px solid #d9d9d9;
              background: #fff;
              box-shadow: 0px 1px 2px 0px rgba(16, 16, 16, 0.05);
              display: flex;
              justify-content: center;
              align-items: center;
              width: 34px;
              height: 34px;
              cursor: pointer;
            }
          }
        }
      }
    }
  }
}

#modal-informasi-baru {
  .modal-content {
    width: 100%;
    max-width: 488px;
    background: #fff;
    padding: 24px 32px;
    border: 0.5px solid #ececec;
    box-shadow:
      0px 1px 3px rgba(16, 16, 16, 0.1),
      0px 1px 2px rgba(16, 16, 16, 0.06);
    border-radius: 6px;

    .close-wrapper {
      display: flex;
      justify-content: flex-end;
    }

    .mib-header {
      color: #101010;
      font-size: 18px;
      font-weight: 500;
      line-height: 28px;
      margin-bottom: 8px;
    }

    .mib-sub-header {
      color: #676767;
      font-size: 14px;
      line-height: 20px;
      padding-bottom: 32px;
      border-bottom: 1px solid #d9d9d9;
      margin-bottom: 16px;
      span {
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
      }
    }

    .mib-form {
      display: flex;

      .mib-form-label {
        width: 98px;
        margin-right: 64px;
        .label {
          color: #101010;
          font-size: 14px;
          font-family: "Inter";
          font-weight: 500;
          line-height: 20px;
          margin-bottom: 8px;
        }
        .info {
          color: #af0000;
          font-size: 10px;
          font-family: Inter;
          line-height: 16.2px;
        }
      }

      .mib-form-field {
        width: calc(100% - 98px - 64px);
        padding-bottom: 40px;

        .mib-field-label {
          color: #676767;
          font-size: 12px;
          font-weight: 500;
          line-height: 18px;
          margin-bottom: 8px;
        }
        .wrapper-mib-calendar-field {
          width: 100%;
          position: relative;

          .mib-calendar-field {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 10px 16px;
            border-radius: 6px;
            border: 1px solid #d9d9d9;
            background: #ffffff;
            box-shadow: 0px 1px 2px 0px rgba(16, 16, 16, 0.05) inset;

            margin-bottom: 32px;
          }
          .react-datepicker {
            top: 60px;
            left: 0;
            width: auto;
          }
          .react-datepicker__month-container {
            width: 100%;
          }
        }

        .mib-nominal-field {
          display: flex;
          align-items: center;
          span {
            width: 50px;
            height: 40px;
            color: #101010;
            font-size: 14px;
            line-height: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
            background: #ececec;
            border: 1px solid #d9d9d9;
          }
          input {
            font-size: 14px;
            line-height: 20px;
            height: 40px;
            display: flex;
            align-items: center;
            width: 100%;
            border: 1px solid #d9d9d9;
            border-left: none;
            padding: 0 16px;
            color: #101010;

            &::placeholder {
              color: #9e9e9e;
            }
          }
        }
      }
    }

    .mib-button {
      display: flex;
      align-items: center;
      justify-content: center;
      padding-top: 16px;
      border-top: 1px solid #d9d9d9;
      button {
        border-radius: 6px;
        box-shadow: 0px 1px 2px 0px rgba(16, 16, 16, 0.05);
        padding: 10px 16px;
        color: #101010;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        width: 100%;
        cursor: pointer;
      }

      .cancel {
        border: 1px solid #d9d9d9;
        background: #ffffff;
      }

      .tambah {
        background: #101010;
        border: 1px solid #101010;
        margin-left: 24px;
        color: #ffffff;
      }
    }

    .mib-loader-modal {
      width: 100%;
      height: 100%;
      max-width: unset;
      background: transparent;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

#modal-hapus-transaksi {
  .modal-content {
    width: 100%;
    max-width: 488px;
    background: #fff;
    padding: 24px 32px;
    border: 0.5px solid #ececec;
    box-shadow:
      0px 1px 3px rgba(16, 16, 16, 0.1),
      0px 1px 2px rgba(16, 16, 16, 0.06);
    border-radius: 6px;

    .close-wrapper {
      display: flex;
      justify-content: flex-end;
    }

    .title {
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      color: #101010;
      margin-bottom: 24px;
    }

    .info {
      font-weight: 400;
      font-size: 12px;
      line-height: 18px;
      color: #676767;
      margin-bottom: 32px;
      span {
        color: #101010;
      }
    }

    .button-wrapper {
      display: flex;
      align-items: center;
      padding-top: 16px;
      border-top: 1px solid #ececec;
      justify-content: space-between;
      .batal {
        cursor: pointer;
        background: #ffffff;
        border: 1px solid #d9d9d9;
        box-shadow: 0px 1px 2px rgba(16, 16, 16, 0.05);
        border-radius: 6px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 10px 16px;
        width: 100%;
        span {
          font-weight: 500;
          font-size: 14px;
          line-height: 20px;
          color: #101010;
        }
      }
      .ya {
        cursor: pointer;
        background: #af0000;
        border: 1px solid #af0000;
        box-shadow: 0px 1px 2px rgba(16, 16, 16, 0.05);
        border-radius: 6px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 10px 16px;
        width: 100%;
        margin-right: 24px;

        span {
          font-weight: 500;
          font-size: 14px;
          line-height: 20px;
          color: #ffffff;
        }
      }
    }
  }
}
