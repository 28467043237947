.uc-textinput-v1 {
  .label {
    font-weight: $font-bold;
    font-size: $fontXS;
    line-height: $ln-height-S;
    margin-bottom: 8px;
  }

  .textinput-wrapper {
    padding: 10px 16px;
    background: #fff;
    border-radius: 6px;
    box-shadow: $bx-shadow-v1;
    border: 1px solid #d9d9d9;

    input {
      width: 100%;
      height: 20px;
      color: $blackV1;
      font-size: $fontM;
      font-weight: $font-normal;
      font-family: $font-family;

      &::placeholder {
        color: $greyV2;
        font-size: $fontM;
        font-weight: $font-normal;
        font-family: $font-family;
      }
    }
    &.disabled {
      background: $greyV4;
      input {
        background: $greyV4;
      }
    }
  }

  &.error {
    .textinput-wrapper {
      border: 1px solid $redV1;

      input {
        color: $redV1;
      }
    }
    .print-error {
      margin-top: 6px;
      color: $redV1;
      font-size: $fontXS;
      line-height: $ln-height-XS;
    }
  }
}
