#profile-picture-banner-4 {
  canvas {
    object-fit: contain;
    // width: 510px;
    height: 657.578px;
    position: absolute;
    right: 0;
    bottom: 0;
  }
}

#profile-picture-banner-5 {
 canvas {
    object-fit: contain;
    width: 620px;
    height: 657.578px;
    position: absolute;
    right: 0;
    bottom: 0;
  }
}
