.laporan-transaksi-wrapper{
  width: 100%;
  height: 100vh;
  overflow-y: auto;
}

@import "./LTHeader.scss";
@import "./LTBody.scss";
@import "./LTFooter.scss";

@import "./DataAgentForm.scss";
@import "./PilihListing.scss";
@import "./DataPropertyDanTransaksi.scss";