.checkboxes {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-auto-rows: 1fr;
  justify-content: space-between;
  align-items: center;
  grid-row-gap: 42px;
  .checkbox-box {
    display: flex;
    align-items: center;
    white-space: nowrap;

    input[type="checkbox"] {
      margin-right: 8px;
      width: 16px;
      height: 16px;
    }
    span {
      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      color: #101010;
    }
  }
}
