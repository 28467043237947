#login-wrapper {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  .login-box {
    width: 100%;
    max-width: 378px;
    background: #ffffff;
    border: 0.5px solid #ececec;
    box-shadow:
      0px 1px 3px rgba(16, 16, 16, 0.1),
      0px 1px 2px rgba(16, 16, 16, 0.06);
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 40px;

    .login-logo {
      width: 38px;
      height: 49px;
      margin-bottom: 40px;
    }

    .header-text {
      font-weight: $font-bolder;
      font-size: $fontXL;
      line-height: calc($ln-height-XXL - 4px);
      margin-bottom: 40px;
    }

    .login-input {
      width: 100%;
      margin-bottom: 24px;

      .custom-info {
        display: flex;
        margin-top: 8px;
        svg {
          width: 16px;
          height: 16px;
          margin-right: 8px;
        }
        span {
          font-size: $fontXS;
          line-height: $ln-height-XS;
          color: $greyV1;
        }
      }
    }

    .login-btn,
    .password-btn,
    .forgot-password,
    .back-btn-wrapper,
    .kirim-link-btn {
      width: 100%;
    }

    .password-btn {
      span {
        font-size: $fontM;
      }
    }

    .forgot-password {
      cursor: pointer;
      border: none;
      background: #fff;
      display: flex;
      justify-content: center;
      box-shadow: none;
      margin-bottom: 56px;
      color: #2356a6;
      text-decoration: underline;
      font-size: $fontS;
      margin-top: 24px;
    }

    .kirim-link-btn {
      margin-bottom: 56px;
    }

    .sandi-baru,
    .confirm-sandi {
      width: 100%;
      margin-bottom: 8px;
    }
    .info-new-password {
      text-align: left;
      width: 100%;
      font-size: $fontXS;
      line-height: $ln-height-XS;
      color: $greyV1;
      margin-bottom: 24px;
      &.last {
        margin-bottom: 50px;
      }
    }
  }
}

.error-custom-info {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  svg {
    margin-right: 4px;
  }
  span {
    color: #af0000;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 16.18px; /* 161.8% */
  }
}

.resend-otp {
  color: #676767;
  font-family: "Inter";
  font-size: 10px;
  font-style: normal;
  line-height: 16.18px; /* 161.8% */
  margin-bottom: 56px;
  cursor: pointer;
  span {
    color: #101010;
    font-family: "Inter";
    font-size: 10px;
    font-style: normal;
    line-height: 16.18px; /* 161.8% */
    text-decoration-line: underline;
    margin-left: 8px;
  }
}
