.google-map-autocomplete {
  width: 100%;
  padding: 10px 16px;
  border: 1px solid $greyV3;
  border-radius: 8px;
  display: flex;
  align-items: center;

  .search-icon{
    width: 24px;
    height: 24px;
    margin-right: 16px;
  }

  .search-input{
    width: 100%;
    height: 20px;
    color: $blackV1;
    
    &::placeholder{
      font-size: $fontM;
      line-height: $ln-height-M;
      color: $greyV2;
    }
  }
}
.pac-container {
  z-index: calc($zIndex + 2) !important;
}