@import "~styles/mixins.scss";

.steps-labels-wrapper {
  @include font-family;

  background: #f5f5f5;
  display: flex;
  justify-content: center;
  .steps-labels {
    padding: 24px 0;
    display: flex;
    align-items: center;
    width: 663px;
    .step-number {
      background: #101010;
      padding: 0 8px;
      border-radius: 4px;
      color: #ffffff;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      margin-right: 19px;
    }
    .step-text {
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: #101010;
    }
  }
}
