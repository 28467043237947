@import "~styles/_variables.scss";

.wrapper-content {
  width: 100%;
  max-width: 422px;
  background: #fff;
  padding: 16px;
  border: 0.5px solid #ececec;
  box-shadow: 0px 1px 3px rgba(16, 16, 16, 0.1), 0px 1px 2px rgba(16, 16, 16, 0.06);
  border-radius: 6px;
  display: flex;
  z-index: calc($zIndex + 2);
  flex-direction: column;
  .wrapper-close {
    display: flex;
    justify-content: flex-end;
    svg {
      width: 16px;
      height: 16px;
      cursor: pointer;
    }
  }

  .wrapper-info {
    padding: 32px 26px;
    border-bottom: 1px solid #ececec;

    .title {
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 28px;
      color: #101010;
      margin-bottom: 8px;
    }
    .info {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #676767;
    }
  }

  .box-content {
    padding: 32px 26px;
    border-bottom: 1px solid #ececec;

    .title {
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      color: #9a6e00;
      margin-bottom: 32px;
    }

    .wrapper-agents {
      display: grid;
      grid-template-columns: 50% 50%;
      grid-column-gap: 24px;
      grid-row-gap: 16px;
      margin-bottom: 24px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .wrapper-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 16px 0;

    button {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 10px 16px;
      box-shadow: 0px 1px 2px rgba(16, 16, 16, 0.05);
      border-radius: 6px;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      cursor: pointer;
      &:active {
        transform: scale(0.9);
        transition: transform 0.1s ease-in-out;
      }
      &:disabled {
        transform: unset;
      }
      &.batal {
        border: 1px solid #d9d9d9;
        color: #101010;
        margin-right: 24px;
      }
      &.lanjutkan {
        color: #ffffff;
        background: #101010;
      }
    }
  }
}
