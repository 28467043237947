.properti-detail-ubah-listing {
  margin-bottom: 40px;
  .ubah-listing-header {
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    color: #101010;
    margin-bottom: 24px;
  }
  .ubah-listing-contact {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #494949;
    margin-bottom: 16px;
  }
  .ubah-listing-ini {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    cursor: pointer;
    background: #ffffff;
    border: 1px solid #101010;
    box-shadow: 0px 1px 2px rgba(16, 16, 16, 0.05);
    border-radius: 6px;
    span {
      font-family: "Inter";
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      color: #101010;
    }
  }
}
