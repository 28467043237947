.wrapper {
  padding: 32px 0;
  border-bottom: 1px solid #f5f5f5;
}

.title {
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
  color: #101010;
  margin-bottom: 32px;
}

.wrapper-informasi{
  display: grid;
  grid-template-columns: 1fr 1fr;
  border-bottom: 1px solid #f5f5f5;
  padding: 24px 108px;
  grid-column-gap: 24px;
  &:last-child{
    border-bottom: none;
    padding-bottom: 0;
  }
}

.pt-0{
  padding-top: 0;
}
