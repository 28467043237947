.data-porperty-dan-transaksi {
  .divider {
    border: 1px solid #d9d9d9;
  }

  //HEADER
  .header-wrapper {
    @include flex-between;
    padding: 32px;

    border-bottom: 0.5px solid #ececec;
    box-shadow: 0px 1px 3px rgba(16, 16, 16, 0.1), 0px 1px 2px rgba(16, 16, 16, 0.06);
    .back-btn {
      width: auto;
      border: 1px solid #d9d9d9;
    }
  }

  //BODY
  .data-prop-body {
    min-height: calc(100vh - 116px - 116px);
    padding: 32px;
    overflow-y: auto;
    border: 0.5px solid #ececec;
    box-shadow: 0px 1px 3px rgba(16, 16, 16, 0.1), 0px 1px 2px rgba(16, 16, 16, 0.06);
    border-radius: 6px;
    padding: 24px 32px;
    .steps-wrapper {
      margin-top: 27px;
      margin-bottom: 28px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      .step-bar {
        display: flex;
        align-items: center;
        color: #9E9E9E;
        .number {
          width: 32px;
          height: 32px;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 16px;
          border-radius: 50%;
          color: #fff;
          margin-right: 8px;
          background: #d9d9d9;
        }

        &.selected {
          color: $blackV1;
          .number {
            background: #bcaf8b;
          }
        }
      }
      .stripe {
        height: 1px;
        border-top: 1px solid #9e9e9e;
        width: 16px;
        margin-left: 16px;
        margin-right: 16px;
      }
    }

    .dpb-form-wrapper {
      border: 0.5px solid #ececec;
      box-shadow: 0px 1px 3px rgba(16, 16, 16, 0.1), 0px 1px 2px rgba(16, 16, 16, 0.06);
      border-radius: 6px;
      padding: 24px 32px;

      .reporting-form-wrapper {
        padding: 48px 75px;
        display: flex;
        .rf-left,
        .rf-right {
          width: 100%;
          display: flex;
          .label-wrapper {
            width: 100%;
            .label-field {
              @include text-14;
            }
            .mandatory-field {
              @include text-14($color: #af0000);
            }
          }
          .input-wrapper {
            width: 100%;
            .select-wrapper,
            ._field {
              margin-bottom: 40px;
            }
          }
        }

        .rf-left {
          padding-right: 80px;
          border-right: 1px solid #d9d9d9;
        }
        .rf-right {
          padding-left: 80px;
        }
      }
    }
  }

  //FOOTER
  .dpt-footer-wrapper {
    @include flex-col-end;
    flex-direction: row;
    padding: 32px;
    border-top: 0.5px solid #ececec;
    box-shadow: 0px -1px 3px rgba(16, 16, 16, 0.1), 0px -1px 2px rgba(16, 16, 16, 0.06);
    .prev-btn,
    .next-btn {
      width: auto;
      border: 1px solid #d9d9d9;
      svg {
        path {
          fill: none;
        }
      }
    }

    .next-btn {
      @include btn-black;
      flex-direction: row-reverse;
      margin-left: 16px;
    }
  }
}
