.wrapper {
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 16px;
  background: #ffffff;
  border: 1px solid #d9d9d9;
  box-shadow: 0px 1px 2px rgba(16, 16, 16, 0.05);
  border-radius: 6px;

  span {
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: #101010;
  }

  &:active {
    transform: scale(0.7);
    transition: transform 0.2s ease-in-out;
  }

  &:disabled {
    transform: unset;
    background: #f5f5f5;
    span {
      color: #676767;
    }
  }

  svg {
    width: 14px;
    height: 14px;
    margin-right: 8px;
  }
}
