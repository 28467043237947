#modal-success-create-transaksi {
  .divider{
    border: 1px solid #ECECEC;
  }
  .modal-content {
    width: 100%;
    max-width: 807px;
    background: #fff;
    padding: 32px;
    border: 0.5px solid #ececec;
    box-shadow: 0px 1px 3px rgba(16, 16, 16, 0.1), 0px 1px 2px rgba(16, 16, 16, 0.06);
    border-radius: 6px;

    .btn-wrapper{
      display: flex;
      justify-content: flex-end;
    }
  }
}
