.kk-header {
  padding: 32px;
  .title {
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 30px;
    line-height: 38px;
    color: #101010;
    margin-bottom: 49px;
  }
  .kk-navbar {
    display: flex;
    border-bottom: 1px solid #ECECEC;
    button {
      padding: 16px;
      min-width: 136px;
      cursor: pointer;
      span {
        font-family: "Inter";
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: #9E9E9E;
      }
    }
    .active {
      border-bottom: 2px solid #c1af82;
      span{
        color: #101010;
      }
    }
  }
}
