.back-button-v1 {
  display: flex;
  align-self: center;
  width: auto;
  cursor: pointer;
  .back-icon {
    width: 14px;
    height: 14px;
    margin-right: 2px;
    path {
      fill: $greyV2;
    }
  }
  span {
    font-size: $fontS;
    font-weight: $ln-height-S;
    color: $greyV2;
  }
}
