.c-modal {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
  z-index: $zIndex;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 40;

  .backdrop {
    z-index: calc($zIndex + 1);
    height: 100%;
    width: 100%;
    position: absolute;
    z-index: 41;
  }
  .modal-content {
    z-index: 42;
  }
}
