.title {
  display: flex;
  margin-bottom: 16px;
}

.durasi-grey {
  color: #d9d9d9;
}

.select-wrapper,
.input-wrapper {
  margin-bottom: 40px;
}

.align-center{
  align-items: center;
}